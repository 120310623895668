import React, {useContext, useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Inscription from "./InscriptionTab/Inscription";
import VirementValidationView from "./VirementValidation/view/VirementValidationView";
import AnnoncesAdministration from "./AnnoncesTab/AnnoncesAdministration";
import FormationsContainer from "./FormationTab";
import JodContainer from "./JodTab";
import {UserContext} from "../../context/user-context";


const useStyles = makeStyles(theme=>({
    root: {
        marginTop:20
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
}));



const Administration = (props)=>{
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const {user} =useContext(UserContext);


    function handleChange(event, newValue) {
        setValue(newValue);
    }

    if(!user.accessToken){
        return(
            <div>
                Vous devez être connecté pour accéder à cette page
            </div>
        )
    }



    return(
        <>
            <Paper square className={classes.root}>
                <Tabs centered value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
                    <Tab label="INSCRIPTION"/>
                    <Tab label="Virement" />
                    <Tab label="Annonces" />
                    <Tab label="JOD" />
                    <Tab label="Formations" />
                </Tabs>
            </Paper>

            {value === 0 && <Inscription />}
            {value === 1 &&  <VirementValidationView/>}
            {value === 2 &&  <AnnoncesAdministration/>}
            {value === 3 &&  <JodContainer/>}
            {value === 4 &&  <FormationsContainer/>}

        </>
    );
}


export default  Administration;
