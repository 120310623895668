import p1 from "./1.png"
import p2 from "./2.png"
import p3 from "./3.png"
import p4 from "./4.png"
import p5 from "./5.png"
import p6 from "./6.png"
import p8 from "./8.png"
import p9 from "./9.png"
import p10 from "./10.png"
import p11 from "./11.png"
import p12 from "./12.png"
import p13 from "./13.png"
import p14 from "./14.png"
import p15 from "./15.png"
import p17 from "./17.png"
import p18 from "./18.png"
import p19 from "./19.png"
import p20 from "./20.png"
import p21 from "./21.png"
import p22 from "./22.png"


const partenairesArray = [
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    p8,
    p9,
    p10,
    p12,
    p13,
    p17,
    p18,
    p19,
    p20,
    p21,
    p22,
];

export default partenairesArray;
