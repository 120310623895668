import React, {useContext, useEffect, useState} from "react";

import {Container, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import config from "../../../config/api"
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BookmarkIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import Dialog from "@material-ui/core/Dialog";
const useStyles = makeStyles(theme=>({

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },


}));


const ReinitPassword = (props)=>{
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [mail, setMail] = useState("");

    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });

    const checkPassword = async () =>{
        if(password!==verifyPassword){
            setPassword("");
            setVerifyPassword("");
            setSnackBarDisplay(
                {
                    open : true,
                    variant : "error",
                    message : "Les mots de passe ne correspondent pas"
                }
            );
            return false;
        }


        try{
            const {data} = await axios.put(config.api+"resetPassword",{
                rd_key : props.match.params.rdStr,
                newPassword : password
            });

            setSnackBarDisplay(
                {
                    open : true,
                    variant : "success",
                    message : "mot de passe modifié, vous pouvez vous connecter"
                }
            );

        }catch (e) {

            setSnackBarDisplay(
                {
                    open : true,
                    variant : "error",
                    message : e.response.data.message
                }
            );
        }
    };
    const checkMail = async()=>{
        if(mail.trim()===""){
            setSnackBarDisplay(
                {
                    open : true,
                    variant : "error",
                    message : "Veuillez encoder un email"
                }
            );
            return false;
        }

        try{
            const {data} = await axios.post(config.api+"resetPassword",{
                mail : mail
            });

            setSnackBarDisplay(
                {
                    open : true,
                    variant : "success",
                    message : "Un Email de réinitialisation a été envoyé"
                }
            );

        }catch (e) {
            setSnackBarDisplay(
                {
                    open : true,
                    variant : "error",
                    message : e.response.data.message
                }
            );
        }

    };




    if(isLoading){
        return (
            <CircularProgress  />
        );
    }


    if(props.match.params.rdStr!=="1"){
        return(
            <Container>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackBarDisplay.open}
                    autoHideDuration={6000}
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                >
                    <MySnackbarContentWrapper
                        onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                        variant={snackBarDisplay.variant}
                        message={snackBarDisplay.message}
                    />
                </Snackbar>
                <Typography variant="h6" color="textSecondary" style={{marginTop: "20px"}}>
                    Entrez votre nouveau mot de passe
                </Typography>
                <Paper square className={classes.root}>
                    <Grid container
                          style={{
                              paddingTop : "10px"
                          }}
                          justify="center">
                        <TextField
                            required
                            id="filled-password-input"
                            onChange={(e)=>setPassword(e.target.value)}
                            value={password}
                            label="Nouveau mot de passe"
                            className={classes.textField}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            variant="filled"
                        />

                        <TextField
                            required
                            id="filled-verifyPassword-input"
                            onChange={(e)=>setVerifyPassword(e.target.value)}
                            value={verifyPassword}
                            label="Mot de passe : Vérification"
                            className={classes.textField}
                            type="password"
                            autoComplete=""
                            margin="normal"
                            variant="filled"
                        />
                        <Button   color="primary" variant="contained"
                                  style={{
                                      margin : "10px"
                                  }}
                                  onClick={checkPassword}>
                            Changer le mot de passe
                            <BookmarkIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>

                </Paper>
            </Container>
        );
    }


    return (
        <Container>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
            <Typography variant="h6" color="textSecondary" style={{marginTop: "20px"}}>
                Quelle est votre adresse mail?
            </Typography>
            <Paper square className={classes.root}>
                <Grid container
                      style={{
                          paddingTop : "10px"
                      }}
                      justify="center">
                    <TextField
                        required
                        id="filled-mail-input"
                        onChange={(e)=>setMail(e.target.value)}
                        value={mail}
                        label="Mail"
                        className={classes.textField}
                        type="textField"
                        autoComplete="current-mail"
                        margin="normal"
                        variant="filled"
                    />
                </Grid>
                <Button   color="primary" variant="contained"
                          style={{
                              margin : "10px"
                          }}
                          onClick={checkMail}>
                    Réinitialiser le mot de passe
                    <BookmarkIcon className={classes.rightIcon} />
                </Button>
            </Paper>
        </Container>
    )



};


export default  ReinitPassword;
