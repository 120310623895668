import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: 20,
        marginTop: 10
    }
}));
const Title = (props) =>{

    const classes = useStyles();

    return(
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item lg={12} className={classes.root}>
                <Typography align="center" variant="h3" color="primary">
                    {props.title}
                </Typography>
                <Divider />
            </Grid>

        </Grid>
    );
}
export default Title;
