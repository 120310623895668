import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from "../Shared/Title";
import ListFormations from "./ListFormations";
import config from "../../config/api";
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Footer from "../Footer/Footer";

const useStyles = makeStyles(theme => ({

    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
    },
    root: {
        minHeight: "100vh"
    },
    progress: {
        margin: theme.spacing(2),
        height : "1000px"

    },
}));

export default function HubFormation(props) {
    const classes = useStyles();
    const [formationsGroup, setFormationsGroup] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(()=>{
        (async () =>{

            try{
                const {data} = await axios.get(config.api+"formations");
                //we ecpect data to contain : formations : [] and formationsType

                const formGroup = data.formationsType.map(type => {
                    const fromArray = data.formations.filter(formation => {
                        return formation.type===type.type
                    })
                        .map(formation => {
                            return {...formation, type : type } ;
                        });
                    return {
                        formations :fromArray,
                        type : type
                    }

                });
                setFormationsGroup(formGroup)
                setIsLoading(false);
            }catch (e) {
                console.log(e)
            }

        })();
    }, []);

    if(isLoading){
        return (
            <Container className={classes.progress}>

                <CircularProgress  />

            </Container>
        );
    }
    return (
        <>
            <Container className={classes.root}>
                <Title title={"Nos Formations"}/>
                {formationsGroup.map((group, index)=> {
                    return(
                        <ExpansionPanel key={group.type.type} expanded={expanded === group.type.displayName} onChange={handleChange(group.type.displayName)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"panel"+group.type.displayName+"-content"}
                                id={"panel"+group.type.displayName}
                            >
                                <Typography className={classes.heading} color={"primary"}>{group.type.displayName}
                                </Typography>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                <ListFormations
                                    formations={group.formations}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                    );
                })}
            </Container>
            <Footer position="relative" marginTop="30px" {...props}/>

        </>

    );
}
