import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {TableHead} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {amber, green} from "@material-ui/core/colors";

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="première page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="page précédente">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="page suivante"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="dernière page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    pending: {
        backgroundColor: amber[500],
        minWidth :200
    },
    ok: {
        backgroundColor: green[500],
        minWidth :200
    },
}));

export default function ClientTable({reservationList}) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, reservationList.length - page * rowsPerPage);



    const displayChip = (status, means)=>{
        if(status==="pending"){
            return(
                <Chip label={means +" : en attente"} className={classes.pending} />
            )
        }
        if(status==="paid"){
            return(
                <Chip label={means +" : payé"} className={classes.ok} />
            )
        }
    };

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Prénom
                            </TableCell>
                            <TableCell>
                                Nom
                            </TableCell>
                            <TableCell>
                                Adresse
                            </TableCell>
                            <TableCell>
                                N° Inami
                            </TableCell>
                            <TableCell>
                                N° de suivi
                            </TableCell>
                            <TableCell>
                                Type de réservation
                            </TableCell>
                            <TableCell>
                                Status du paiement
                            </TableCell>
                        </TableRow>
                    </TableHead>


                    <TableBody>
                        {reservationList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row =>{

                            return (
                                <>
                                    <TableRow key={row.User.id}>
                                        <TableCell scope="row">
                                            {row.User.firstName}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.User.lastName}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.User.address_street},
                                            {row.User.address_number},
                                            {row.User.address_postalCode},
                                            {row.User.address_city},
                                            {row.User.address_country}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.User.inami}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.transactionId}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.isStudent ? "Etudiant" : "Dentiste"}
                                        </TableCell>
                                        <TableCell scope="row">
                                            { displayChip(row.status, row.payment_means) }
                                        </TableCell>
                                    </TableRow>

                                    {row.Assistantes.map(ass => {
                                        return(
                                            <TableRow key={row.User.id}>
                                                <TableCell scope="row">
                                                    {ass.firstName}
                                                </TableCell>
                                                <TableCell scope="row">
                                                    {ass.lastName}
                                                </TableCell>
                                                <TableCell colSpan={3}>
                                                    Assistante inscrite par {row.User.firstName} {row.User.lastName}
                                                </TableCell>
                                                <TableCell scope="row">
                                                    assistante
                                                </TableCell>
                                                <TableCell scope="row">
                                                    { displayChip(row.status, row.payment_means) }
                                                </TableCell>
                                            </TableRow>
                                        )

                                    })}
                                </>

                            )}

                        )}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={reservationList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Client par page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </Paper>
    );
}
