import React from 'react';
import Grid from "@material-ui/core/Grid";
import pierre from '../../../../../assets/images/logo_white.png';
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        height : 200,
        width: 200
    },
});

const FormationsSection = (props) => {
    const classes = useStyles();

    return (
        <Grid

            container
            direction="row"
            alignItems="center"
            component={"div"}
            style={{
                paddingTop : "100px",
                paddingBottom : "100px",
                paddingLeft : "10%",
                backgroundColor:"#3a405a",

            }}
        >

            <Grid item
                  sm={12}
                  md={4}>
                <img alt="Pierre" src={pierre}   />

            </Grid>

            <Grid item
                  sm={12}
                  md={8}
           >
                <div style={{
                    textAlign: "justify",
                    textJustify: "inter-word",
                    paddingRight : "10%",
                    color: "white"
                }}>
                    <Typography variant="body1" color="inherit" component="p" className={classes.leftIcon}>
                        Le Collège d'Omnipratique Dentaire est une asbl belge spécialisée dans l'organisation de formations continues en sciences dentaires.
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="inherit" component="p" className={classes.leftIcon}>
                        Le COD, c'est un label de qualité dans la formation comme en témoignent les fiches d'évaluations que vous remplissez à la fin de chaque cours.
                        Nous nous efforçons d'inviter des conférenciers de renom. Nous recrutons à l'étranger afin d'offrir à la Belgique les
                        compétences des autres pays d'Europe et ce, toujours en français.
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="inherit" component="p" className={classes.leftIcon}>
                        Le COD, c'est aussi toute notre expertise que nous mettons au service des dentistes depuis 2001!
                        Chaque année, nous organisons plus d'une dizaine de cours afin de permettre à nos confrères d'obtenir leur accréditation et/ou leur agrément.
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="inherit" component="p" className={classes.leftIcon}>
                        Mais avant tout, le COD, c'est l'aventure d'une grande famille : celle des dentistes qui nous font confiance depuis plus de 19 ans!
                    </Typography>

                </div>
            </Grid>
        </Grid>

    );

};


export default FormationsSection;
