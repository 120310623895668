import React, {useState} from "react";
import {Container, makeStyles} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../../Shared/MySnackBarContentWrapper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme=>({
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height : "100%"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },

    dense: {
        marginTop: 16,
    },

}));

const VirementValidationSearchField = ({searchFunction})=>{
    const classes = useStyles();
    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });

    const [searchInfos, setSearchInfos] = useState({
        num:"",
        lastName:"",
        firstName:""
    });
    const handleChange = name=>event => {
        setSearchInfos({...searchInfos, [name]: event.target.value})
    };

    return (
        <Container>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
            <Grid container
                  direction="row"
                  justify="space-between"
            >
                <Grid item xs={12} >

                    <Typography variant="h8" color="textSecondary" component="p" className={classes.dense}>
                        Renseignez une ou plusieurs des informations ci dessous pour effectuer une recherche.
                        <br/>
                        Le logiciel cherchera en priorité le numéro de suivi. Si le numéro n'est pas renseigné, ou est erroné, il cherchera sur base du nom
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        id="filled-email-input"
                        onChange={handleChange("num")}
                        label="Numéro de suivi"
                        className={classes.textField}
                        type="text"
                        margin="normal"
                        variant="filled"
                    />

                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        id="filled-email-input"
                        onChange={handleChange("lastName")}
                        label="Nom"
                        className={classes.textField}
                        type="text"
                        margin="normal"
                        variant="filled"
                    />

                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField
                        id="filled-email-input"
                        onChange={handleChange("firstName")}
                        label="Prénom"
                        className={classes.textField}
                        type="text"
                        margin="normal"
                        variant="filled"
                    />

                </Grid>
            </Grid>
            <Grid container
                  direction="row"
                  justify="flex-end"
            >
                <Grid item xs={2} className={classes.dense}>
                    <Button  variant={"contained"}  color="primary" onClick={()=>searchFunction(searchInfos)}>
                        Rechercher
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.dense}/>
                </Grid>
            </Grid>
        </Container>
    )
};



export default VirementValidationSearchField;
