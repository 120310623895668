import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardFormation from "./CardFormation";




const ListFormations = (props)=>{

    return(
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={4}
                    component={"div"}
                >
                    {props.formations.map((f, index)=>{
                        return(
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={12}
                                xs={12}
                                key={f.id}

                                component={"div"}>
                            <CardFormation
                                formation={f}
                            />
                            </Grid>
                        );
                    })}
                </Grid>

    )
};

export default ListFormations;
