import React from "react";
import Title from "../Shared/Title";
import {Container, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Footer from "../Footer/Footer";
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),

    },
    paddedRight : {
        paddingLeft : "30px"
    }
}));
function ProtectionDonnee(props) {
    const classes = useStyles();

    return(
        <>
            <Title title={"Politique de protection des données"}/>
            <Container maxWidth="lg" style={{        minHeight: "100vh"}} >

                <Paper className={classes.root}>
                    <Typography component="div" variant="p" color="textPrimary" >
                        En complétant entièrement le formulaire d’inscription à une activité du C.O.D. Asbl,
                        chaque inscrit a accepté les conditions générales et consent à la collecte, au traitement
                        et au stockage de ces données par la même Asbl.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        Ces données comprennent votre nom et prénom, le nom de votre cabinet ou société,
                        votre adresse, votre mail, votre numéro de téléphone, votre numéro INAMI.
                    </Typography>
                    <Typography component="p" variant="p" color="textPrimary" >
                        Le C.O.D. Asbl utilise ces données pour la gestion de la participation de chaque inscrit
                        à ses activités de formation ainsi que pour compléter le dossier d’accréditation à
                        l’INAMI.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        Lors de chaque journée de Formation Continue sponsorisée, le C.O.D. Asbl
                        communique la liste des participants aux firmes sponsors du jour de formation afin
                        justifier la présence du participant pour lui permettre de bénéficier du tarif de
                        commande avantageux. Cette liste reprend le Nom, Prénom, adresse et le mail du
                        participant.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        Toutes ces données sont conservées sous forme de fichier informatisé et protégé au
                        siège de l’Asbl C.O.D. pour une durée limitée. Ces données seront détruites au
                        moment de la dissolution et liquidation de l’Asbl C.O.D.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        Chaque personne inscrite ou participante peut, sur simple demande clairement
                        formulée à <a href="mailto:info@cod.eu.com">info@cod.eu.com</a>, modifier ses données ou en limiter l’utilisation.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        Pour la promotion des activités du COD Asbl, des photographies de foule prises lors des activités
                        de formations sont publiées dans le journal JOD ainsi que sur le site internet et sur le
                        groupe privé FB Collège d’Omnipratique Dentaire – COD. Chacun peut, sur simple
                        demande clairement formulée à <a href="mailto:info@cod.eu.com">info@cod.eu.com</a>, limiter la publication d’une photo.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        Nous n'utilisons aucun traceur publicitaire sur ce site.
                    </Typography>
                </Paper>
            </Container>
            <Footer position="relative" marginTop="30px" {...props}/>
        </>
    );
}

export default ProtectionDonnee;
