import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {KeyboardDatePicker, KeyboardTimePicker} from "@material-ui/pickers";

/*
{
            "id": 2,
            "agrement": 25,
            "description": "ceci est une description",
            "name": "fC1 : ma super formation",
            "illustrationURL": "plus tard ca",
            "prix": 250,
            "type": "FC",
            "createdAt": "2019-12-12T10:01:30.000Z",
            "updatedAt": "2019-12-12T10:01:30.000Z",
            "Accreditations": [
                {
                    "id": 2,
                    "domaine": "dom",
                    "numero": "num",
                    "unit": "unit",
                    "formation_id": 2,
                    "createdAt": "2019-12-12T10:01:30.000Z",
                    "updatedAt": "2019-12-12T10:01:30.000Z"
                }
            ],
            "Speakers": [
                {
                    "id": 1,
                    "firstName": "Pascal",
                    "lastName": "Paillet",
                    "title": "Mr",
                    "createdAt": "2019-12-12T10:01:21.000Z",
                    "updatedAt": "2019-12-12T10:01:21.000Z",
                    "Formation_speaker": {
                        "formation_id": 2,
                        "speaker_id": 1,
                        "createdAt": "2019-12-12T10:01:30.000Z",
                        "updatedAt": "2019-12-12T10:01:30.000Z"
                    }
                }
            ],
            "ConferenceRooms": [
                {
                    "id": 1,
                    "address_street": "Rue de l'Aujoûle",
                    "address_number": "51",
                    "address_city": "Eprave",
                    "address_postalCode": "5580",
                    "address_country": "Belgique",
                    "name": "Eprave",
                    "createdAt": "2019-12-12T10:01:21.000Z",
                    "updatedAt": "2019-12-12T10:01:21.000Z",
                    "Formation_room": {
                        "formation_id": 2,
                        "room_id": 1,
                        "createdAt": "2019-12-12T10:01:30.000Z",
                        "updatedAt": "2019-12-12T10:01:30.000Z"
                    }
                }
            ],
            "Calendars": [
                {
                    "id": 2,
                    "startDate": "2020-11-28T13:27:53.000Z",
                    "stopDate": "2020-11-28T13:27:53.000Z",
                    "comments": null,
                    "formation_id": 2,
                    "createdAt": "2019-12-12T10:01:30.000Z",
                    "updatedAt": "2019-12-12T10:01:30.000Z"
                }
            ]
        }
    ],
    "formationsType": [
        {
            "type": "FC",
            "displayName": "Formation continue"
        },
        {
            "type": "RX",
            "displayName": "Radio-protection"
        },
        {
            "type": "ENN",
            "displayName": "Enneagramme"
        },
        {
            "type": "PR",
            "displayName": "Peer review"
        }
    ]
 */

const FormationsForm = ({formationType, conferenceRoomsList, speakerList, newFormation, setNewFormation
                        },
                        ref) =>{

    const handleChange = name => event => {
        setNewFormation({ ...newFormation, [name]: event.target.value });
    };
    const handleCalendarsChange = (index, name) => value => {
        const updated = newFormation;
        updated.Calendars[index][name]=value;
        setNewFormation({...updated});
    };

    const handleAccreditationChange = (index, name) => event => {
        const updated = newFormation;
        updated.Accreditations[index][name]=event.target.value;
        setNewFormation({...updated});
    };
    const handleSpeakerChange = (index) => event => {
        const updated = newFormation;
        updated.Speakers[index]=  event.target.value;
        setNewFormation({...updated});
    };

    const handleRoomChange = (index) => event => {
        const updated = newFormation;
        updated.ConferenceRooms[index]= event.target.value;
        setNewFormation({...updated});
    };

    const addDate = ()=>{
        const updated = newFormation;
        updated.Calendars.push({id:(newFormation.Calendars.length+1)*-1, startDate :new Date(), stopDate:new Date()});
        setNewFormation({...updated});
    };
    const addSpeaker = ()=>{
        const updated = newFormation;
        updated.Speakers.push(speakerList[0]);
        setNewFormation({...updated});
    };
    const addAccred = ()=>{
        const updated = newFormation;
        updated.Accreditations.push({id: (newFormation.Accreditations.length+1)*-1, domaine:"", unit :"", numero:"" });
        setNewFormation({...updated});
    };

    const deleteSpeaker =(id)=>{
        const speakersList = newFormation.Speakers.filter(s=>{
            return s.id !==id;
        });
        setNewFormation({...newFormation, Speakers : speakersList});
    };

    const deleteAccred =(id)=>{
        const accredList = newFormation.Accreditations.filter(s=>{
            return s.id !==id;
        });
        setNewFormation({...newFormation, Accreditations : accredList});
    };

    const deleteCalendar =(id)=>{
        const calendarList = newFormation.Calendars.filter(s=>{
            return s.id !==id;
        });
        setNewFormation({...newFormation, Calendars : calendarList});
    };


    const displayDatePicker = (index, id)=>{
        return(

            <Grid container
                  direction="row"
                  justify="space-between"
                  key={id}
                  style={{border : "solid grey 1px", padding : "10px"}}>
                <Grid item xs={12}>

                    <Typography variant="p" color="textSecondary" component="p" style={{textAlign : "center"}}>
                        Date et heure
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date"
                        value={newFormation.Calendars[index].startDate}
                        onChange={(val)=>{
                            handleCalendarsChange(index, "startDate")(val);
                            handleCalendarsChange(index, "stopDate")(val);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    {console.log("startDate : ",newFormation.Calendars[index].startDate)}
                    {console.log("stopDate : ",newFormation.Calendars[index].stopDate)}
                </Grid>
                <Grid item xs={4} >
                    <Button  variant={"contained"}  color="secondary" onClick={()=>{deleteCalendar(id)}} style={{marginTop : "20px"}} >
                        X
                    </Button>
                </Grid>
                <Grid item xs={6}>

                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="début"
                        format="HH:mm"
                        value={newFormation.Calendars[index].startDate}
                        onChange={handleCalendarsChange(index, "startDate")}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>

                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="fin"
                        format="HH:mm"
                        value={newFormation.Calendars[index].stopDate}
                        onChange={handleCalendarsChange(index, "stopDate")}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </Grid>
            </Grid>

        )
    };

    const displayInformationsGenerale =()=>{
        return(
            <>
                <Typography variant="h5" color="textSecondary" component="p" style={{textAlign : "center", paddingBottom :"20px", paddingTop : "10px"}}>
                    Nouvelle Formation
                </Typography>
                <Grid container
                      justify="space-between"
                      direction="row"

                >
                    <Grid item
                          xs={6}

                    >
                        <TextField
                            required
                            error={(newFormation.name)?newFormation.name.trim()==="" : true}
                            variant="standard"
                            value={newFormation.name}
                            onChange={handleChange("name")}
                            label="Titre de la formation"
                            style={{width : "100%", paddingRight : "10px"}}
                        />

                        <TextField
                            variant="standard"
                            value={newFormation.description}
                            onChange={handleChange("description")}
                            label="Description"
                            multiline
                            rows="4"
                            style={{width : "100%", paddingRight : "10px", paddingTop :"20px"}}
                        />

                            <TextField
                                required
                                error={isNaN(parseInt(newFormation.agrement))}
                                type="number"
                                variant="standard"
                                value={newFormation.agrement}
                                onChange={handleChange("agrement")}
                                label="Agrément"
                                style={{paddingTop :"20px", paddingRight : "10px"}}
                            />
                        <Grid container
                              direction="row"
                              justify="space-between"
                              style={{margin : "10px"}}

                        >

                            <TextField
                                required
                                error={isNaN(parseInt(newFormation.prix))}
                                type="number"
                                variant="standard"
                                value={newFormation.prix}
                                onChange={handleChange("prix")}
                                label="Prix standard (en €)"
                                style={{paddingTop :"20px"}}
                            />
                            <TextField
                                required
                                error={isNaN(parseInt(newFormation.prix_young))}
                                type="number"
                                variant="standard"
                                value={newFormation.prix_young}
                                onChange={handleChange("prix_young")}
                                label="Prix jeune diplômé (en €)"
                                style={{paddingTop :"20px"}}
                            />
                            <TextField
                                required
                                error={isNaN(parseInt(newFormation.prix_assistante))}
                                type="number"
                                variant="standard"
                                value={newFormation.prix_assistante}
                                onChange={handleChange("prix_assistante")}
                                label="Prix assistante (en €)"
                                style={{paddingTop :"20px"}}
                            />
                            <TextField
                                required
                                error={isNaN(parseInt(newFormation.prix_student))}
                                type="number"
                                variant="standard"
                                value={newFormation.prix_student}
                                onChange={handleChange("prix_student")}
                                label="Prix étudiant (en €)"
                                style={{paddingTop :"20px"}}
                            />

                        </Grid>

                        <Grid container
                              direction="row"
                              justify="space-between"
                              style={{margin : "10px"}}
                        >

                            <Grid item
                                  xs={12}
                            >
                                <FormControl variant="filled" style={{width:"100%"}}
                                             required
                                >
                                    <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
                                    <Select
                                        value={newFormation.type}
                                        onChange={handleChange("type")}
                                    >
                                        {formationType.map(ft =>{
                                            return(
                                                <MenuItem value={ft.type} key={ft.type}>{ft.displayName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>





                        </Grid>
                        {displaySallePicker()}

                        {displaySpeakerPicker()}


                    </Grid>
                    <Grid item
                          xs={6}
                          style={{paddingLeft : "20px"}}
                    >

                        {
                            newFormation.Calendars.map((c, index)=>{
                                return displayDatePicker(index, c.id);
                            })
                        }


                        <Button  variant={"contained"}  color="primary" onClick={addDate} style={{marginTop : "20px"}} >
                            Ajouter une date
                        </Button>

                    </Grid>
                </Grid>
            </>
        )
    };

    const displaySallePicker = ()=>{
        return(
            <>
                <Grid container
                      justify="flex-start"
                      direction="row"
                >
                    <Grid item xs={12} style={{padding :"20px"}}>
                        <Grid container
                        >
                            <FormControl variant="filled" style={{width:"100%"}}
                            >
                                <InputLabel >Salle</InputLabel>
                                <Select
                                    value={newFormation.ConferenceRooms[0]}
                                    onChange={handleRoomChange(0)}
                                >
                                    {conferenceRoomsList.map((cr) =>{
                                        return(
                                            <MenuItem value={cr} key={cr.id}>{cr.name +" : "+cr.address_street+" "+cr.address_city}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    };

    const displayAccreditationsPicker = ()=>{
        return(
            <>
                {
                    newFormation.Accreditations.map((acc, index)=>{
                        return(
                            <Grid container
                                  justify="flex-start"
                                  direction="row"
                                  key={()=>{
                                      return acc.id
                                  }}
                            >
                                <Grid item xs={3} style={{padding :"20px"}}>
                                    <TextField
                                        variant="standard"
                                        value={newFormation.Accreditations[index].domaine}
                                        onChange={handleAccreditationChange(index, "domaine")}
                                        label="Domaine"
                                        style={{width : "100%", paddingRight : "10px"}}
                                    />
                                </Grid>
                                <Grid item xs={3} style={{padding :"20px"}}>
                                    <TextField
                                        variant="standard"
                                        value={newFormation.Accreditations[index].numero}
                                        onChange={handleAccreditationChange(index, "numero")}
                                        label="Numero"
                                        style={{width : "100%", paddingRight : "10px"}}
                                    />
                                </Grid>
                                <Grid item xs={3} style={{padding :"20px"}}>
                                    <TextField
                                        variant="standard"
                                        value={newFormation.Accreditations[index].unit}
                                        onChange={handleAccreditationChange(index, "unit")}
                                        label="Unit"
                                        style={{width : "100%", paddingRight : "10px"}}
                                    />
                                </Grid>
                                <Grid item xs={3} style={{ padding :"20px"}}>
                                    <Button  variant={"contained"}  color="secondary" onClick={()=>{deleteAccred(acc.id)}} style={{marginTop : "20px"}} >
                                        X
                                    </Button>
                                </Grid>

                            </Grid>

                        )
                    })
                }
                <Grid item xs={12}>
                    <Button  variant={"contained"}  color="primary" onClick={addAccred} style={{marginTop : "20px"}} >
                        Ajouter une Accréditation
                    </Button>
                </Grid>
            </>
        )
    };

    const displaySpeakerPicker = ()=>{
        return(
            <>
                <Grid container
                      justify="flex-start"
                      direction="row"
                >
                    {
                        newFormation.Speakers.map((sp, index)=>{
                            return(
                                <>
                                    <Grid item xs={8} style={{ padding :"20px"}}>

                                        <FormControl variant="filled" style={{width:"100%"}}
                                        >
                                            <InputLabel >Conférencier {index+1}</InputLabel>
                                            <Select
                                                value={newFormation.Speakers[index]}
                                                onChange={handleSpeakerChange(index)}
                                            >
                                                {speakerList.map((cr) =>{
                                                    return(
                                                        <MenuItem value={cr} >{cr.title +" "+cr.lastName+" "+cr.firstName}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} style={{ padding :"20px"}}>
                                        <Button  variant={"contained"}  color="secondary" onClick={()=>{deleteSpeaker(sp.id)}} style={{marginTop : "20px"}} >
                                            X
                                        </Button>
                                    </Grid>
                                </>
                            )
                        })
                    }


                    <Grid item xs={12}>
                        <Button  variant={"contained"}  color="primary" onClick={addSpeaker} style={{marginTop : "20px"}} >
                            Ajouter un conférencier
                        </Button>
                    </Grid>
                </Grid>
            </>
        )
    };

    return (
        <form  noValidate autoComplete="off">


            {displayInformationsGenerale()}
            <hr style={{width: "100%", marginTop:"20px"}}/>
            <Typography variant="h5" color="textSecondary" component="p" style={{textAlign : "center"}}>
                Accréditations
            </Typography>
            {displayAccreditationsPicker()}



        </form>

    )

};

export default React.forwardRef(FormationsForm);
