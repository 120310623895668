import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingTop : theme.spacing(2),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Réserver pour vous', 'Ajouter des assistantes', 'Résumé'];
}



export default function BookingStepper({handleStepperStep,activeStep, handlePaid} ) {
    const classes = useStyles();
    const steps = getSteps();

    function handleNext() {
        handleStepperStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        handleStepperStep(prevActiveStep => prevActiveStep - 1);
    }



    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                    <Grid container
                    direction="row"
                    justify="space-around">
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Retour
                            </Button>
                            {activeStep === steps.length - 1 && (
                                <Button variant="contained" color="primary" onClick={handlePaid}>
                                    Payer
                                </Button>
                            )}
                            {activeStep < steps.length -1&& (
                                <Button variant="contained" color="primary" onClick={handleNext}>
                                    Suivant
                                </Button>
                            )}
                        </div>
                    </Grid>

            </div>
        </div>
    );
}
