import React, {useContext, useEffect, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Container} from "@material-ui/core";
import FormationCombobox from "./FormationCombobox";
import ClientTable from "./ClientTable";
import Summary from "./Summary";
import axios from "axios";
import {UserContext} from "../../../context/user-context";
import config from "../../../config/api";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles(theme=>({
    root: {
        marginTop:20
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
}));

const Inscription = ()=> {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [reservationList, setReservationList] = useState([]);
    const [currentIdDisplayed, setCurrentIdDisplayed]=useState("Aucune");
    const [displayedId, setDisplayedId]=useState(-1);
    const [formationsFlattedList, setFormationsFlattedList] = useState([]);
    const {user}= useContext(UserContext);
    useEffect(()=>{
        (async ()=>{
            const {data} = await axios.get(config.api+"formations",{
                headers : {
                    'authorization' : "Bearer "+ user.accessToken
                }
            });
            setFormationsFlattedList(data.formations);

            const allFormations = await axios.get(config.api+"adminInscription?formationId=all",
                {
                    headers : {
                        'authorization' : "Bearer "+ user.accessToken
                    }
                });

            const toSet = allFormations.data.map(f => {
                return f.Reservations ;
            }).flat();

            setReservationList(toSet);
            setIsLoading(false)
        })();

    },[]);

    const downLoadFile = async ()=>{
        const {data} = await axios.post(config.api+"adminInscription",{
            formationId : displayedId
        });
        const file = new Blob([data], {
            type: "text/csv"
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

    };

    const onChangeFormation = (value)=>{
        setDisplayedId(value)
        setCurrentIdDisplayed(value)
        if(value==="Aucune"){
            setReservationList([]);
            return;
        }
        setIsLoading(true);
        (async ()=>{
            const {data} = await axios.get(config.api+"adminInscription?formationId="+value,
                {
                    headers : {
                        'authorization' : "Bearer "+ user.accessToken
                    }
                });
            setReservationList(data.Reservations);
            setIsLoading(false);
        })();
    };

    if(isLoading){
        return (
            <CircularProgress className={classes.progress} />
        );
    }
    return(
        <Container>
            <FormationCombobox formationsFlattedList={formationsFlattedList} callBack={onChangeFormation} current={currentIdDisplayed}/>
            <Summary  reservationList={reservationList} />
            <ClientTable reservationList={reservationList}/>
            <Button  variant={"contained"}  color="secondary" onClick={downLoadFile} disabled={currentIdDisplayed==="Aucune"}>
                Télécharger sous forme de fichier
            </Button>


        </Container>

    );
}

export default Inscription
