

const isYoungInami = (inami)=>{
    //si on rate le cast, c'est que le num inami est mal formaté
    try {
        const thisYear = parseInt(new Date().getFullYear().toString().slice(2,4));
        const graduateYear = parseInt(inami.slice(1, 3));
        return graduateYear>=thisYear-2 && graduateYear<thisYear+1
    }catch (e) {
        return false;
    }
};
export const performPrice =(formation, inami, isStudent, isAssistante) =>{



    //cas 1 : étudiant :
    if(isStudent){
        return {
            price: formation.prix_student,
            message: "Prix étudiant(e)"
        }
    }

    //cas 2 : assistante :
    if(isAssistante){
        return {
            price: formation.prix_assistante,
            message: "Prix assitant(e)"
        }
    }

    console.log(isYoungInami(inami))
    //cas 3 : jeune diplômé
    if(inami && isYoungInami(inami)){
        return {
            price: formation.prix_young,
            message: "Prix jeune diplômé"
        }
    }


    //cas 4 : pas de réduction
    return {
        price: formation.prix,
        message: ""
    };

};

//calcul les réductions de fidélité
//(les réductions fidélités ont un effet rétroactif,
//si un client a déjà acheté 2 formations, et qu'il est juste en train de réserver la 3e
// il a automatiquement droit à la réduction des 3FC
//seul un dentist ou un étudiant a droit à la réduction
//par ailleurs, les étudiants n'étant pas forcément inscrit, on fera le match sur leur nom/prénom
//ainsi, si un dentiste s'inscrit avec 2 étudiants, ils bénificeront chacuns des réductions indépendamment

export const performReduction= (reservationList)=>{

    const rules = (countFC) => {
        //moins de 3 formations, pas de reduction
        if(countFC<3){
            return  0.;
        }

        //entre 3 et 5 formations : réduction
        if(countFC>=3 && countFC<=5){
            return (countFC - 2) * 50.;
        }

        //plus de 5 formations : en principe, illogique, mais sait on jamais, plafond à 5red
        return 150.;
    };


    //Un compte est lié à un dentiste ou à un étudiant. Mais un étudiant ne peut inscrire un dentiste et inversément
    //autrement dit, si j'ai dans mon panier ou mon historique des réservations tantot pour un étudiant, tantot pour un dentiste,
    // c'est que ces réservation on été effectuée avec le même compte, donc au même nom et donc, il s'agit de la même personne.
    // Donc, un étudiant en 2e master qui suit 3 FC avant son diplôme bénéficiera également des réductions fidélité lorsqu'il s'inscrira après son diplôme

    //on compte le nombre de FC totale dans l'historique + panier pour connaitre la reduc total
    const redCount = reservationList.filter(res => {
        //la réservation est faite pour un dentiste et concerne une formation continue
        return (res.Formation.type==="FC");
    }).length;

    //on calcul la réduction déjà percu (si il y a déjà plus de 3 fc dans le panier, le client a déjà commencé à avoir des réductions)
    const alreadyGot = reservationList.filter(res => {
        //la réservation est faite pour un dentiste et concerne une formation continue
        return ( res.Formation.type==="FC" && res.status!=="panier");
    }).length;

    //la réduction effective pour ce panier est la réduction total - la réduction déjà percue
    const reduction = rules(redCount)-rules(alreadyGot);

    //la réservation list est soit pour un student, soit pour un dentiste, mais pas les deux
    return {
        reduction : reduction,
        fcCount : redCount,
        fcRedGot : alreadyGot
    };

};
