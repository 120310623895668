import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles(theme=>({
    root: {
        marginTop:20
    },
    padding: {
        padding: theme.spacing( 2),
    },

}));
const Summary = ({ reservationList})=> {
    const classes = useStyles();



    const countInscription = (data)=>{
        let count =0;
        data.forEach(d => {
            count++;
            count += d.Assistantes.length;
        });
        return count;
    };

    return(
        <Paper className={classes.padding}>
        <Grid
            container
            direction="row"
            justify="space-between"
        >
                <Typography variant="body1" color="textSecondary" component="p" >
                    Nb d'inscrits : {countInscription(reservationList)}
                </Typography>



        </Grid>
        </Paper>
    );

};

export default Summary;
