import React, {useContext, useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import SummingRow from "./SummingRow";
import BookingStepper from "./BookingStepper";
import SelfReservation from "./SelfReservation";
import AssistanteAddReservation from "./AssistanteAddReservation";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import ConfirmationPayementModal from "./ConfirmationPayementModal";
import {UserContext} from "../../../context/user-context";
import axios from "axios";
import config from "../../../config/api";


const useStyles = makeStyles(theme=>({

    title:{
        margin: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop : theme.spacing(2),
        paddingBottom : theme.spacing(2),
        textAlign: "center"
    },
}));

//formationsFlattedList est la liste des formations dispo flatted
const BookingTab = ({formationsFlattedList})=>{
    const classes = useStyles();
    //stepper state
    const [activeStep, setActiveStep] = React.useState(0);
    const {userReservation, isDentist, forceRefreshPanier, user} = useContext(UserContext);
    //ne contient que les reservations formatées présentent dans le panier
    const [confirmationPayementOpen, setConfirmationPayementOpen] = useState(false);
    const [onlyPanierFormatedReservation, setOnlyPanierFormatedReservation] = useState([]);
    const [userData, setUserData]=useState({});

    useEffect(()=>{
        (async()=>{
            try{
                const {data} = await axios.get(config.api+"users", {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken
                    }
                });
                setUserData(data);

            }catch (e) {
             console.log(e)
            }
        })();

    },[]);




    const handleStepperStep = (step)=>{
        setActiveStep(step)
    };

    const handlePaid = async ()=>{
        setConfirmationPayementOpen(true);

    };


    useEffect(()=>{
        //preparation de la liste de réservation
        const formated = userReservation.reservationsList
            .map(res => {

                const formation = formationsFlattedList.find((f)=>{
                    return f.id===res.Formation.id;
                }) ;
                res.Formation = formation;
                return res;
            })
            .sort((res1, res2 )=>{
                if(res1.Formation.name<res2.Formation.name){
                    return -1;
                }
                if(res1.Formation.name>res2.Formation.name){
                    return 1;
                }
                return 0

            })
            .filter(res => {
                return res.status==="panier";
            });
            setOnlyPanierFormatedReservation(formated);

    }, [formationsFlattedList,userReservation.reservationsList]);



    if(!onlyPanierFormatedReservation[0]){
        return(
            <Container className={classes.instructions}>
                    Aucune formation dans votre panier
            </Container>
        )
    }


    return (
        <Container>
            <ConfirmationPayementModal open={confirmationPayementOpen} onClose={()=>{
                forceRefreshPanier();
                setConfirmationPayementOpen(false)
            }}/>
            <BookingStepper activeStep={activeStep} handleStepperStep={handleStepperStep} isDentist={isDentist} handlePaid={handlePaid}/>

            {activeStep===0 && (
                <SelfReservation onlyPanierFormatedReservation={onlyPanierFormatedReservation} userData={userData}/>
            )}
            {activeStep===1 &&  (
                <AssistanteAddReservation formatedReservations={onlyPanierFormatedReservation}/>
            )}
            {(activeStep===2) &&  (
              <SummingRow onlyPanierFormatedReservation={onlyPanierFormatedReservation} userData={userData}/>
            )}

        </Container>
    );
};


export default  BookingTab;
