import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Container} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop : "20px"
    },
    table: {
        minWidth: 650,
    },
});


export default function AnnoncesAdminTable({annoncesList, onDelete}) {
    const classes = useStyles();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <Container>


            <Paper className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Delete</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Tel</TableCell>
                            <TableCell>mail</TableCell>
                            <TableCell>type</TableCell>
                            <TableCell>description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {annoncesList.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <Button  variant={"contained"}  color="primary" onClick={()=>{onDelete(row.id)}}>
                                        Supprimer
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {new Date(row.publicationDate).toLocaleDateString("fr-FR", options)}
                                </TableCell>
                                <TableCell>{row.phone}</TableCell>
                                <TableCell>{row.mail}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>

    );
}
