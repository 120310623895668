import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const FormationCombobox = ({formationsFlattedList, callBack, current})=> {

    const classes = useStyles();

    const [values, setValues] = React.useState(current);

    function  handleChange(event) {
        setValues(event.target.value);
        callBack(event.target.value);
    }

    return (
        <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
                <InputLabel>Formation</InputLabel>
                <Select
                    value={values}
                    onChange={handleChange}
                >
                    <MenuItem value="Aucune" key="aucune" >Aucune</MenuItem>

                    {formationsFlattedList.map((f, index)=> {
                        return (
                            <MenuItem key={index} value={f.id}>{f.name}</MenuItem>
                        )
                    })
                    }

                </Select>
            </FormControl>
        </form>
    );


};

export default FormationCombobox;
