import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import arr from './async-double-arrow-down.svg';

const animation = keyframes`
  from {
    opacity:1;
    -webkit-transform:scaleY(1.0) translate(0px);
    transform:scaleY(1.0) translate(0px)
  }
  
  to {
    opacity:.5;
    -webkit-transform:scaleY(1.05) translate(0px, 20px);
    transform:scaleY(1.05) translate(0px, 20px)
  }
`;

const animationRule = css`
  ${animation} 1s infinite alternate;
`;

const Animation = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => (props.animate ? `calc(20px + ${props.marginBottom})` : props.marginBottom)};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  display: inline-block;
  animation: ${props => (props.animate ? animationRule : 'none')};
  cursor: ${props => (props.showClickable ? 'pointer' : 'default')};
  position: absolute;
                    top : 70%;
                    left:50%;
                    opacity: 0;
`;

export const AscDoubleArrow = (props) => {
  const { navTo } = props;
  return (
    <Animation {...props} src={arr} onClick={navTo} alt="\/" />
  );
};

AscDoubleArrow.propTypes = {
  navTo: PropTypes.func,
};

AscDoubleArrow.defaultProps = {
  navTo: () => {},
};

Animation.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  animate: PropTypes.bool,
  showClickable: PropTypes.bool,
};

Animation.defaultProps = {
  width: '50px',
  height: 'auto',
  marginTop: 'auto',
  marginBottom: 'auto',
  marginRight: 'auto',
  marginLeft: 'auto',
  animate: false,
  showClickable: false,
};
