import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import CardFormation from "../../../Formations/CardFormation";
import Button from "@material-ui/core/Button";
import FormationsWisardContainer from "./FormationsWisardContainer";
import config from "../../../../config/api";
import axios from "axios";

const FormationsUpdateContainer = ({formationType,
                                       conferenceRooms,
                                       speakerList,
                                       formationList,
                                   pushUpdate,
                                   toUpdate,setToUpdate}) =>{

    const [step, setStep]= useState(0);



    //attention, les éléments Select du wizard se mette à jour sur base de la référence des objets.
    //autrement dit, les conférenciers et salles doivent etre de même référent dans  conferenceRooms et speakerList
    //que dans la formation choisie. On doit faire un tri explicite, sinon les élément select plante
    //j'ai pas trouvé d'autre façon de faire à ce jour
    const initToUpdate = (f)=>{

        const next = {};

        if(f.Speakers.length>0){
            next.Speakers =f.Speakers.map(sp=>{
                return speakerList.find(spInList =>{
                    return spInList.id ===sp.id
                });
            });
        }
        if(f.ConferenceRooms.length>0){
            next.ConferenceRooms =f.ConferenceRooms.map(sp=>{
                return conferenceRooms.find(spInList =>{
                    return spInList.id ===sp.id
                });
            });
        }
        setToUpdate({...f, ...next});
    };




    return(
        <>
            {step===0 && (
                <Grid container
                      justify={"space-between"}

                style={{marginTop : "20px"}}>
                    {formationList.map(f => {
                      return(
                          <Grid item
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                key={f.id}
                                style={{ overflow : "hidden", paddingBottom :"50px"}}
                          >



                                  <Button variant="contained" color="secondary"
                                          onClick={()=>{
                                               setStep(1);
                                               initToUpdate(f);
                                           }}>
                                      Update
                                  </Button>


                                  <CardFormation
                                      disableReservation
                                      formation={f}
                                  />




                          </Grid>
                      )
                    })}

                </Grid>
            )}
            {step===1 && (
                <FormationsWisardContainer formationType={formationType}
                                           conferenceRooms={conferenceRooms}
                                           speakerList={speakerList}
                                           onFinished={pushUpdate}
                                           newFormation={toUpdate}
                                           setNewFormation={setToUpdate}
                />
            )}

        </>

    )


};


export default FormationsUpdateContainer;
