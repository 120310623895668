import React, {useContext, useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import Typography from "@material-ui/core/Typography";
import MailIcon from '@material-ui/icons/Mail';
import {green} from "@material-ui/core/colors";
import DeleteIcon from '@material-ui/icons/Delete';
import {UserContext} from "../../../context/user-context";
import axios from "axios";
import config from "../../../config/api";
import ConfirmDialog from "../../Shared/ConfirmDialog";


const useStyles = makeStyles(theme=>({
    root: {

        marginTop:20

    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    confirmed: {
        color : green[600],

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
}));



const PersonalDataTab = ()=>{
    const classes = useStyles();
    const {user, ejectUser, refreshToken, setUser} = useContext(UserContext);
    const [isEditable, setIsEditable] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isPrompted, setIsPrompted]=useState({open: false});
    const [isDeleting, setIsDeleting]= useState(false);

    const [values, setValues] = React.useState({
        mail: '',
        password: '',
        verifyPassword :'',
        inami:'',
        address_street:'',
        address_number:'',
        address_postalCode:'',
        address_city:'',
        address_country:'',
        lastName:'',
        firstName:'',
    });

    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });

    useEffect(()=>{
        (async()=>{
            try{
                const {data} = await axios.get(config.api+"users", {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken
                    }
                });
                setValues(data);

            }catch (e) {
                setSnackBarDisplay({
                    open: true,
                    variant: "error",
                    message: e.response.data.message
                });
            }
        })();

    },[]);

    useEffect(()=>{
        if(!user.isMailChecked){
            const interval = setInterval(async ()=>{
                const {data} = await axios.get(config.api+"users",{
                    headers :{
                        "authorization" : "Bearer "+user.accessToken
                    }
                });
                if(!data){
                    return ;
                }
                if(data.isMailChecked){
                    await refreshToken();
                }

            }, 2000);
            return () => clearInterval(interval);

        }

    },[user]);

    const handleDelete = async (sure)=>{

        if(!sure){
            return;
        }
        setIsDeleting(true);
        try{
            const {data} = await axios.delete(config.api+"users",
                {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken
                    }
                });
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "Profil supprimé !"
            });
            setTimeout(()=>{
                ejectUser();
            }, 1000)

        }catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
        }    };

    const handleSave =  async () =>{
        setIsLoadingSave(true);
        setIsEditable(false);
        try{
            const {data} = await axios.put(config.api+"users",
                values,
                {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken
                    }
                });

            //data contains true if the mail has been changed
            if(data){
                setUser({...user, isMailChecked : false})
            }

            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "enregistré !"
            });

        }catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
        }
        setIsLoadingSave(false);


    };

    const handleResendMail = async ()=>{
        try{
            await axios.put(config.api+"auth", {},{
                headers : {
                    "authorization": "Bearer "+user.accessToken
                }
            });
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "mail envoyé !"
            });
        }catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
        }

    };



//modifie les state contenant les infos utilisateurs
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };



    return(

        <Container maxWidth="sm">
            <ConfirmDialog
                handleClose={handleDelete} title={"Supprimer mon compte"}
                text="Attention, cette action est irréversible. Vous ne pourrez plus vous connecter ni réserver de formation.<br/>
                Si vous avez des formations réservées, vos données seront conservées jusqu'à la fin de l'année civile afin de gérer les accréditations"

                open={isPrompted.open}/>
            <Grid container
                  justify="flex-start"
                  direction="column"
            >
                <TextField
                    disabled={!isEditable}
                    id="filled-email-input"
                    onChange={handleChange("mail")}
                    value={values.mail}
                    label="Mail"
                    type="text"
                    autoComplete=""
                    margin="normal"
                    variant="filled"
                />
                {!user.isMailChecked && (
                    <>
                        <Typography variant="body2" color="secondary" >
                            Votre email n'est pas confirmé. Veuillez cliquer sur le lien que vous avez reçu.
                        </Typography>
                        <Button color="secondary"   disabled={isEditable} onClick={handleResendMail}>
                            Renvoyer mail de confirmation
                            <MailIcon className={classes.rightIcon} />
                        </Button>

                    </>
                )}
                {user.isMailChecked && (
                    <>
                        <Typography variant="body2" className={classes.confirmed} >
                            Email confirmé
                        </Typography>
                    </>
                )}
                <TextField
                    disabled={!isEditable}
                    id="filled-name-input"
                    onChange={handleChange("lastName")}
                    value={values.lastName}
                    label="Nom"
                    type="text"
                    autoComplete=""
                    margin="normal"
                    variant="filled"
                />
                <TextField
                    disabled={!isEditable}
                    id="filled-surname-input"
                    onChange={handleChange("firstName")}
                    label="Prénom"
                    value={values.firstName}

                    type="text"
                    autoComplete=""
                    margin="normal"
                    variant="filled"
                />
                <TextField
                    disabled={!isEditable}
                    id="filled-address1-input"
                    onChange={handleChange("address_street")}
                    label="Adresse"
                    value={values.address_street}
                    type="text"
                    autoComplete=""
                    margin="normal"
                    variant="filled"
                />
                <TextField
                    disabled={!isEditable}
                    onChange={handleChange("address_number")}
                    value={values.address_number}
                    label="Numero et boite"
                    type="text"
                    autoComplete=""
                    margin="normal"
                    variant="filled"
                />
                <Grid container
                      direction="row"
                      justify="space-between">

                    <TextField
                        disabled={!isEditable}
                        id="filled-cp-input"
                        onChange={handleChange("address_postalCode")}
                        value={values.address_postalCode}
                        label="Code postal"
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />
                    <TextField
                        disabled={!isEditable}
                        id="filled-city-input"
                        onChange={handleChange("address_city")}
                        label="Ville"
                        type="text"
                        value={values.address_city}
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />
                </Grid>

                    <TextField
                        disabled={!isEditable}
                        onChange={handleChange("address_country")}
                        label="Pays"
                        type="text"
                        value={values.address_country}
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />
                <TextField
                    disabled={!isEditable}
                    id="filled-inami-input"
                    onChange={handleChange("inami")}
                    label="N° Inami"
                    type="text"
                    value={values.inami}
                    autoComplete=""
                    margin="normal"
                    variant="filled"
                />
            </Grid>
            <Grid container
                  direction="row"
                  justify="space-between"
            >
                <Button  variant={"contained"}  color="secondary" onClick={()=>setIsPrompted({open : true})}  disabled={isDeleting || isLoadingSave}>
                    {!isDeleting && "supprimer compte"}
                    {!isDeleting &&   <DeleteIcon className={classes.rightIcon} />}

                    {isDeleting && <CircularProgress color="inherit" className={classes.rightIcon} />}

                </Button>
                {!isEditable && !isLoadingSave && (
                    <Button  variant={"contained"}  color="primary" onClick={()=>setIsEditable(!isEditable)}  disabled={isDeleting}>
                        Editer
                        <EditIcon className={classes.rightIcon} />
                    </Button>
                )}
                {isEditable && (
                    <Button  variant={"contained"}  color="primary" onClick={handleSave} disabled={isDeleting || isLoadingSave} >
                        {!isLoadingSave && "Sauver"}
                        {!isLoadingSave &&  <SaveIcon className={classes.rightIcon} />}
                    </Button>
                )}
                {!isEditable && isLoadingSave &&(
                    <Button  variant={"contained"}  color="primary" disabled  >
                        {isLoadingSave &&  <CircularProgress color="inherit" className={classes.rightIcon} />}
                    </Button>
                )}

            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
        </Container>
    );
}


export default  PersonalDataTab;
