import React, {useContext, useState} from "react";
import config from "../../../config/api";
import axios from "axios";
import {UserContext} from "../../../context/user-context";

//responsable de la connexion BD, et de la récup de data
const withVirementValidationHOC = WrappedComponent => props =>{

    const [reservationList, setReservationList] = useState([]);
    //store current client data (i don't want to refetch it everytime I need it

    //num de suivit = transactionId
    //nom/prénom => get User=> userId => fetch based on clientId
    const {user} = useContext(UserContext)

    async function searchByNum(num) {

        try{
                const {data}=await axios.get(config.api+"adminVirementValidation?num="+num,{
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            });
            const obj = {};
            obj[num]= data;
            setReservationList(obj);
            return true;
        }catch (e) {

        }
    }

    async function updated(key, updatedData){
            console.log(updatedData)
        if(!updatedData){
            console.log("error");
            return;
        }
        setReservationList({
            ...reservationList,
            [key] : updatedData
        });

    }

    async function searchByName({lastName, firstName}) {


        try{
            const {data}=await axios.get(config.api+"adminVirementValidation?firstName="+firstName+"&lastName="+lastName, {
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            });
            // Accepts the array and key
            const groupBy = (array, key) => {
                // Return the end result
                return array.reduce((result, currentValue) => {
                    // If an array already present for key, push it to the array. Else create an array and push the object
                    (result[currentValue[key]] = result[currentValue[key]] || []).push(
                        currentValue
                    );
                    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                    return result;
                }, {}); // empty object is the initial value for result object
            };

            // Group by color as key to the person array
            const groupedData = groupBy(data, 'transactionId');
            console.log(groupedData)
            setReservationList(groupedData);
            return true;

        }catch (e) {

        }
    }

    const searchAndUpdate = async  ({num, lastName, firstName})=>{
        //if num is provided, search by num
        if(num+"") {
            const ret = await searchByNum(num + "");
            //if num is found... abort any other operation
            if (ret) {
                return;
            }
        }
        if(lastName+"" || firstName+""){
            //if name is provided, search by name
            const ret = await searchByName({lastName, firstName});
            if (ret) {
                return;
            }
        }
        setReservationList([]);
    };



    return <WrappedComponent data={reservationList}  searchFunction={searchAndUpdate} updated={updated} {...props}/>

};


export default withVirementValidationHOC;
