import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DescriptionIcon from '@material-ui/icons/Description';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import ExploreIcon from '@material-ui/icons/Explore';
import Chip from "@material-ui/core/Chip";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { Markup } from 'interweave';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import MySnackbarContentWrapper from "../Shared/MySnackBarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import MakeListUniqueKey from "../../utils/MakeListUniqueKey";
import { withRouter } from "react-router-dom";
import {UserContext} from "../../context/user-context";
import config from "../../config/api";
const useStyles = makeStyles(theme=>({
    card: {
        maxWidth: 345,
        height: "100%",
        display: "grid"
    },
    media: {
        height: 140,
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    content: {
        alignSelf: "end",
    },
    buttonBot: {
        display: "flex",
        alignSelf: "end",
    },
    appBar: {
        position: 'relative',
        marginBottom: "10px"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    marged: {
        marginBottom: 10,
    },
    cover: {
        objectFit: "cover",
        height: 400,
        width: "100%"
    },
    chip: {
        margin: theme.spacing(1),
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const prettyHour = (date)=>{
    let hour = date.getHours();
    let min = date.getMinutes();

    if(hour<10){
        hour = ""+"0"+hour;
    }

    if(min<10){
        min = ""+"0"+min;
    }

    return hour+"h"+min;
}

const CardFormation = withRouter(({history, formation, disableReservation}) =>  {
    const classes = useStyles();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [open, setOpen] = React.useState(false);
    const {userReservation, setUserReservation, isLoggedIn} = useContext(UserContext);
    const [currentReservation, setCurrentReservation]= useState(undefined);
    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });

    useEffect(()=>{
        const foundRes = userReservation.reservationsList.find(res => {
            return res.Formation.id+""===formation.id+''
        });

        setCurrentReservation(foundRes);
    }, [userReservation,formation.id]);


    const defineReservationButtonText = () => {

        if(!isValideDate()){
            return  (<Button  variant={"contained"}  color="primary" disabled>
                Passé
                <BookmarkIcon className={classes.rightIcon} />
            </Button>);
        }
        if(!currentReservation){
            return  (<Button  variant={"contained"}  color="primary" onClick={handleReservation} disabled={disableReservation}>
                Réserver
                <BookmarkIcon className={classes.rightIcon} />
            </Button>)
        }
        if(currentReservation.status==="panier"){
            return  (<Button  variant={"contained"}  color="primary"  onClick={()=>history.push("/profil")} disabled={disableReservation}>
                Dans le Panier
                <BookmarkIcon className={classes.rightIcon} />
            </Button>)
        }

        return  (<Button  variant={"contained"}  color="primary"  disabled >
            réservé
            <BookmarkIcon className={classes.rightIcon} />
        </Button>)


    };

    const handleReservation = () =>{
        if(!isLoggedIn()){
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: "Vous devez vous connecter pour réserver"
            });
            return;
        }

        const reservation = {
            id: MakeListUniqueKey(20),
            Formation : {
                id : formation.id
            },
            date: new Date().toISOString(),
            status: "panier",
            clientType :"dentist",
            Assistantes:[]
        };
        setUserReservation({reservationsList : [...userReservation.reservationsList, reservation]});
        setCurrentReservation(reservation);
        setSnackBarDisplay({
            open: true,
            variant: "success",
            message: "ajouté"
        });

    };


    function handleClickInfos() {
        setOpen(true);
    };

    function handleCloseInfos() {
        setOpen(false);
    };

    function isValideDate(){
        const now = new Date();
        let date = new Date(formation.Calendars[0].startDate);
        //plus d'inscription 24h avant le cours
        date.setHours(date.getHours()-24);
        return now.getTime() <= date.getTime();

    }
    const displayDate=()=>{
        return (
            <Typography variant="body2" color="textSecondary" component="div" className={classes.leftIcon}>
                <EventAvailableIcon className={classes.leftIcon}/>
                {formation.Calendars.map((calendar, index) =>{
                    if(index===0){
                        return     new Date(calendar.startDate).toLocaleDateString("fr-FR", options)+" ";
                    }else{
                        return  "et " +new Date(calendar.startDate).toLocaleDateString("fr-FR", options)+" ";
                    }
                })}
                {!isValideDate() && (
                    <Typography variant="body2" color="secondary" component="p" className={classes.leftIcon}>
                        Les inscriptions ne sont plus autorisées
                    </Typography>
                )}
            </Typography>
        )
    };
    const displayAccred = ()=>{
        return(
            <>
                {formation.Accreditations[0] && (
                    <>
                        <Typography variant="body1" color="textSecondary" component="p" className={classes.leftIcon}>
                            <BeenhereIcon className={classes.leftIcon}/>
                            {formation.Accreditations.length>1? "Accréditations" : "Accréditation"}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.leftIcon}>
                            {formation.Accreditations.map((a) =>{
                                return  (
                                    <>
                                        Domaine {a.domaine} pour {a.unit} unités
                                        <br/>
                                    </>);
                            })}
                        </Typography>
                    </>)}
                {!formation.Accreditations[0] &&
                <Typography variant="body1" color="textSecondary" component="p" className={classes.leftIcon}>
                    <BeenhereIcon className={classes.leftIcon}/>
                    Accréditation demandée
                </Typography>
                }
            </>
        )
    };

    const displayConferenciers = () =>{
        return(
            <>
                {formation.Speakers[0] && (
                    <>
                        <Typography variant="body1" color="textSecondary" component="p" className={classes.leftIcon}>
                            <PeopleOutlineIcon className={classes.leftIcon}/>
                            Conférencier{(formation.Speakers[1])&&("s")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.leftIcon}>
                            {formation.Speakers.map((s) =>{
                                return  (
                                    <>
                                        {s.title+" "+s.lastName+" "+s.firstName}
                                        <br/>
                                    </>);
                            })}
                        </Typography>
                    </>)}
            </>
        )
    };

    const handleItineraire =()=>{

        window.location.href="https://www.google.com/maps/place/"+formation.ConferenceRooms[0].address_street +", "+formation.ConferenceRooms[0].address_number
                +" "+formation.ConferenceRooms[0].address_postalCode+" "+formation.ConferenceRooms[0].address_city
    };


    const displayHoraire = (calendar)=>{
      const startDate = new Date(calendar.startDate);
      const stopDate = new Date(calendar.stopDate);
      return "de "+prettyHour(startDate)+" à "+prettyHour(stopDate);

    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Grid container
                          direction="column"
                          justify="flex-start"
                          spacing={1}
                    >
                        <Grid item lg={12}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {formation.name}
                            </Typography>
                        </Grid>
                        <Grid item lg={12}>
                            {displayDate()}
                        </Grid>

                    </Grid>
                    <Divider className={classes.divider}/>
                    <Grid container
                          direction="column"
                          justify="flex-start"
                          spacing={1}
                    >
                        <Grid item lg={12}>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.leftIcon}>
                                <EuroSymbolIcon className={classes.leftIcon}/>
                                {formation.prix}
                            </Typography>
                        </Grid>
                        <Grid item lg={12}>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.leftIcon}>
                                <LocationOnIcon className={classes.leftIcon}/>
                                {formation.ConferenceRooms[0].name}
                            </Typography>
                        </Grid>
                        <Grid item lg={12}>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.leftIcon}>
                                <AccessTimeIcon className={classes.leftIcon}/>
                                {displayHoraire(formation.Calendars[0])}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider}/>
                    {displayAccred()}

                </CardContent>
                <CardActions className={classes.buttonBot}>
                    <Grid container
                    spacing={6}
                    alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Button  variant={"contained"} color="primary" onClick={handleClickInfos}>
                                Plus d'infos
                                <InfoIcon className={classes.rightIcon} />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {defineReservationButtonText()}

                        </Grid>


                    </Grid>

                </CardActions>
            </Card>
            <Dialog maxWidth={"md"} fullWidth open={open} onClose={handleCloseInfos} TransitionComponent={Transition}>


                <DialogContent>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseInfos} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formation.name}
                            </Typography>

                            <Chip label={formation.prix}
                                  className={classes.chip}
                                  icon={<EuroSymbolIcon />}
                                  color="primary"
                            />
                        </Toolbar>
                    </AppBar>

                    <Grid
                        component={"div"}
                        container
                        direction="row"
                        justify="flex-start"
                        spacing={3}
                    >
                        <Grid component={"div"}
                              item
                              lg={6}>

                            <Typography variant="body1" color="textSecondary" component="p" className={classes.marged}>
                                <LocationOnIcon className={classes.leftIcon}/>
                                {formation.ConferenceRooms[0].name}

                            </Typography>
                            <Button  variant={"contained"}  color="primary" className={classes.marged} onClick={handleItineraire}>
                                <ExploreIcon className={classes.rightIcon} />
                                Itinéraire
                            </Button>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.marged}>
                                {formation.ConferenceRooms[0].address_street +", "+formation.ConferenceRooms[0].address_number
                                +" "+formation.ConferenceRooms[0].address_postalCode+" "+formation.ConferenceRooms[0].address_city}
                                <br/>
                            </Typography>
                            <Divider className={classes.divider}/>
                            {displayDate()}
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.marged}>
                                <AccessTimeIcon className={classes.leftIcon}/>
                                {displayHoraire(formation.Calendars[0])}
                            </Typography>
                            <Divider className={classes.divider}/>
                            {displayAccred()}
                            <Divider className={classes.divider}/>

                            <Typography variant="body2" color="textSecondary" component="p" className={classes.leftIcon}>
                                Agrément :  {formation.agrement} heures
                            </Typography>
                            <Divider className={classes.divider}/>
                            {displayConferenciers()}
                            <Divider className={classes.divider}/>
                        </Grid>
                        <Grid component={"div"}
                              item
                              lg={6}
                        >
                            <img src={config.server+formation.illustrationURL} alt={"image"+formation.name} className={classes.cover}/>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Typography variant="body1" color="textSecondary" component="p" className={classes.leftIcon}>
                            <DescriptionIcon className={classes.leftIcon}/>
                            Description
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.leftIcon}>
                            <Markup content={formation.description} />
                        </Typography>
                    </Grid>

                    {defineReservationButtonText()}

                </DialogContent>
            </Dialog>
        </>
    );
});
export default CardFormation;
