import React, {useEffect, useRef, useState} from "react";
import FormationsFormsView from "./FormationsFormsView";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../../Shared/ConfirmDialog";
import CardFormation from "../../../Formations/CardFormation";
import Typography from "@material-ui/core/Typography";
import MySnackbarContentWrapper from "../../../Shared/MySnackBarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";


const FormationsWisardContainer = ({newFormation,setNewFormation, onFinished,formationType, conferenceRooms, speakerList}) =>{






    const stepData = [
        {
            buttonName : "Aperçu",
            handler : ()=>{

                const test = newFormation.name.trim()==="" || !newFormation.agrement || !newFormation.prix;

                if(test){
                    setSnackBarDisplay({
                        open: true,
                        variant: "error",
                        message: "vous devez encoder les champs obligatoires"
                    });
                    return;
                }
                //conserve uniquement les accréditations complétées
                const accredArray = newFormation.Accreditations.filter(a=>{
                    return !(a.domaine.trim() ==="" && a.unit.trim()==="" && a.numero.trim()==="")
                });
                setNewFormation({...newFormation, Accreditations:  accredArray});
                setStep(1);
            }
        },
        {
            buttonName : "Enregistrer",
            handler : ()=>{
                setOpen(true);
            }
        },
        {
            buttonName : "En cours...",
            disabled : true,
            handler : ()=>{
            }
        },

    ];

    const ref = useRef(null);
    const [step, setStep]=useState(0);
    const [open, setOpen] = useState(false);

    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });





    const handleClose = (response)=>{
        setOpen(false);
        if(!response){
            setStep(0);
            return;
        }

        onFinished(ref.current.files[0]);
    };

    const next =()=>{
        stepData[step].handler();
    };



    return(
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
            <ConfirmDialog
                handleClose={handleClose}
                open={open}
                title={"Voulez vous enregistrer la formation?"}
                text={"Etes-vous sûr de vouloir sauvegarder la formation?"}
            />
            <Grid container
                  direction="row">
                {(step===0 || step===2) && (
                    <>
                        <Button style={{width : "100%", marginTop:"20px"}} variant="contained" color="secondary"
                                disabled={stepData[step].disabled}
                                onClick={next}>
                            {stepData[step].buttonName}
                        </Button>
                    </>
                )
                }
                {step===1 && (
                    <>
                        <Button style={{width : "50%", marginTop:"20px"}} variant="contained" color="primary"
                                onClick={()=>setStep(0)}>
                            Retour
                        </Button>

                        <Button style={{width : "50%", marginTop:"20px"}} variant="contained" color="secondary"
                                disabled={stepData[step].disabled}
                                onClick={next}>
                            {stepData[step].buttonName}
                        </Button>
                    </>
                )}
            </Grid>

            {step===0 &&  <FormationsFormsView
                formationType={formationType}
                conferenceRoomsList={conferenceRooms}
                speakerList ={speakerList}
                ref={ref}
                newFormation={newFormation}
                setNewFormation={setNewFormation}
            />}
            {step===1 && (
                <>
                    <Grid container
                          direction="row"
                          justify="center"
                          style={{ paddingTop : "20px"}}>
                        <Typography variant="body1" color="textSecondary" component="p" style={{textAlign : "center", paddingTop :"20px"}} >
                            Choisissez une illustration. Elle ne s'affichera pas sur l'aperçu, c'est normal
                        </Typography>
                        <Grid
                            item
                            xs={6}
                        >
                            <label htmlFor="raised-button-file">
                                Illustration
                            </label>
                            <input
                                accept="image/png, image/jpeg"
                                type="file"
                                ref={ref}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                          direction="row"
                          justify="center"
                          style={{ paddingTop : "20px"}}>
                        <Grid item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}>
                            <CardFormation
                                disableReservation
                                formation={newFormation}
                            />
                        </Grid>
                    </Grid>


                </>
            )
            }
            {step===2 && <CircularProgress  />}
        </>

    )


};


export default FormationsWisardContainer;
