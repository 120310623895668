import React from "react";
import withVirementValidationHOC from "../VirementValidationHOC";
import VirementValidationSearchField from "./VirementValidationSearchField";
import VirementValidationTableResult from "./VirementValidationTableResult";



const VirementValidationView = ({data,searchFunction, updated})=>{

    return (
        <>
            <VirementValidationSearchField searchFunction={searchFunction}/>
            <VirementValidationTableResult data={data} updated={updated}/>
        </>

    )
};



export default withVirementValidationHOC(VirementValidationView);
