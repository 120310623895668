 const utils = {
    AnnoncesTypeFormat: (type)=>{
        switch (type) {
            case "remettre" :
                return "A remettre";
            case "vendre" :
                return "A vendre";
            case "cherche" :
                return "Cherche";
            default :
                return "Aucune type";
        }
    },
     ClientTypeFormat: (isStudent)=>{

         return (isStudent)? "Etudiant" : "Dentiste";

     }


}

export default utils;
