import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, {useContext, useEffect, useState} from "react";
import {DialogTitle, makeStyles} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import config from "../../../config/api"
import {UserContext} from "../../../context/user-context";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme=>({

    appBar: {
        position: 'relative',
    },
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height : "100%"
    },

    circular: {
        minHeight: 100,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    padded : {
        marginTop : theme.spacing(2),
        marginBottom : theme.spacing(2),
    }

}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationPayementModal = (props)=>{

    const classes = useStyles();
    const {userReservation, user, isDentist} = useContext(UserContext);
    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [displayVirementInfos, setDisplayVirementInfos] = useState(false);
    const [displayErrorInfos, setErrorInfos] = useState(false);
    const [virementInfos, setVirementInfos] = useState("");
    const [cgAcceptance, setCgAcceptance] = useState(false);

    const [values, setValues] = useState({
        billing_Title: "Mr",
        billing_address_street: "rue",
        billing_address_number: "nb",
        billing_address_city: "city",
        billing_address_postalCode: "cp",
        billing_address_country: "Belgium",
    });

    const [usePersonalAddress, setUsePersonalAddress] = useState(true);
    const [personalData, setPersonalData]=useState({});
    const [paymentMeans, setPaymentMeans] = useState('online');

    useEffect(()=>{
        (async()=>{
            try{
                const {data} = await axios.get(config.api+"users", {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken
                    }
                });
                setPersonalData(data);
                setValues({
                    billing_Title: data.firstName +" "+data.lastName,
                    billing_address_street: data.address_street,
                    billing_address_number: data.address_number,
                    billing_address_city: data.address_city,
                    billing_address_postalCode: data.address_postalCode,
                    billing_address_country: data.address_country,
                });
            }catch (e) {
                setSnackBarDisplay({
                    open: true,
                    variant: "error",
                    message: e.response.data.message
                });
            }
        })();
    },[]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleChangePaiementWay = (way) =>{
        setPaymentMeans(way)
    };

    const handleChangeAddress= (bool)=>{
        if(bool){
            setValues({
                billing_Title: personalData.firstName +" "+personalData.lastName,
                billing_address_street: personalData.address_street,
                billing_address_number: personalData.address_number,
                billing_address_city: personalData.address_city,
                billing_address_postalCode: personalData.address_postalCode,
                billing_address_country: personalData.address_country,
            });
        }
        setUsePersonalAddress(bool);
    };




    const handlePaid =  async ()=>{
        if(cgAcceptance!==true){
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: "Vous devez accepter les conditions générales pour continuer"
            });
            return;
        }
        const reservationList = userReservation.reservationsList.filter(res=> {
                    return res.status==="panier";
        }).map(res => {
                return {
                    ...res,
                    formation:undefined
                }
            });
        try{
            setIsLoading(true);
            const post ={
                    isStudent : !isDentist,
                    reservationData : reservationList,
                    paymentMeans,
                    billingInfos : values
            };

            const {data} = await axios.post(config.api+"reservations", post,
                {
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            });

            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "enregistré"
            });

            if(paymentMeans==="online"){
                window.location.href=data.redirectUrl
            }

            if(paymentMeans==="virement"){
                setIsLoading(false);
                setDisplayVirementInfos(true);
                setVirementInfos(data);
            }

            // await forceRefreshPanier();
        }catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
            setIsLoading(false)
            setErrorInfos(true);

        }

    };

    if(isLoading){
        return (
            <Dialog maxWidth={"sm"} fullWidth open={props.open} TransitionComponent={Transition} >

                <DialogContent className={classes.container}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                Génération des données de paiement
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogContent>
                <Grid container
                    justify="center"
                      className={classes.circular}
                >
                    <CircularProgress className={classes.progress} />
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackBarDisplay.open}
                    autoHideDuration={6000}
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                >
                    <MySnackbarContentWrapper
                        onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                        variant={snackBarDisplay.variant}
                        message={snackBarDisplay.message}
                    />
                </Snackbar>
            </Dialog>
        );
    }

    if(displayErrorInfos){
        return (
            <Dialog maxWidth={"sm"} fullWidth open={props.open} onClose={props.onClose} TransitionComponent={Transition} >
                <DialogContent className={classes.container}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                Un problème est survenu
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogContent>
                <Grid container
                      justify="center"
                      direction="column"
                      className={classes.circular}
                >
                    <Typography color="textSecondary" className={classes.title}>
                       Assurez vous d'avoir bien rempli toutes les informations demandées dans l'onglet "Données personnelles" <br/>
                    </Typography>
                    <Typography color="textSecondary" className={classes.title}>
                        Assurez vous d'avoir bien vérifié votre adresse mail. Déconnectez-vous et reconnectez-vous si nécessaire<br/>
                    </Typography>
                </Grid>
            </Dialog>
        );
    }

    if(displayVirementInfos){
        return (
            <Dialog maxWidth={"sm"} fullWidth open={props.open} onClose={props.onClose} TransitionComponent={Transition} >
                <DialogContent className={classes.container}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                Virement
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogContent>
                <Grid container
                      justify="center"
                      direction="column"
                      className={classes.circular}
                >
                    <Typography color="textSecondary" className={classes.title}>
                        Merci de virer le montant : {virementInfos.amount}€ sur le compte : BE32 0013 5455 6702 <br/>
                    </Typography>
                    <Typography color="textSecondary" className={classes.title}>
                        Indiquez en communication libre votre nom et prénom ainsi que le numéro de suivi : {virementInfos.transactionId}
                    </Typography>
                </Grid>
            </Dialog>
        );
    }
    return(
        <Dialog fullScreen open={props.open} TransitionComponent={Transition} onClose={props.onClose}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
            <DialogTitle>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Adresse de facturation
                        </Typography>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent className={classes.container}>
                <Container>

                <Grid
                    container
                    justify="flex-start"
                    direction="column">
                    <form>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usePersonalAddress}
                                    onChange={()=>handleChangeAddress(true)}
                                    value="checkedA"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2" color="textSecondary" component="p" >
                                    Utiliser mon adresse personnelle
                                </Typography>
                            }
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!usePersonalAddress}
                                    onChange={()=>handleChangeAddress(false)}
                                    value="checkedA"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2" color="textSecondary" component="p" >
                                    Je suis en société
                                </Typography>
                            }
                        />
                    </form>
                    <TextField
                        disabled={usePersonalAddress}
                        id="filled-address1-input"
                        onChange={handleChange("billing_Title")}
                        label="Nom"
                        value={values.billing_Title}
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />
                    <TextField
                        disabled={usePersonalAddress}

                        id="filled-address1-input"
                        onChange={handleChange("billing_address_street")}
                        label="Adresse"
                        value={values.billing_address_street}
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />
                    <TextField
                        disabled={usePersonalAddress}

                        id="filled-address1-input"
                        onChange={handleChange("billing_address_number")}
                        label="Numéro et boite"
                        value={values.billing_address_number}
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />

                    <Grid container
                          direction="row"
                          justify="space-between">

                        <TextField
                            disabled={usePersonalAddress}

                            id="filled-cp-input"
                            onChange={handleChange("billing_address_postalCode")}
                            value={values.billing_address_postalCode}
                            label="Code postal"
                            type="text"
                            autoComplete=""
                            margin="normal"
                            variant="filled"
                        />
                        <TextField
                            disabled={usePersonalAddress}

                            id="filled-city-input"
                            onChange={handleChange("billing_address_city")}
                            label="Ville"
                            type="text"
                            value={values.billing_address_city}
                            autoComplete=""
                            margin="normal"
                            variant="filled"
                        />
                    </Grid>
                    <TextField
                        disabled={usePersonalAddress}

                        id="filled-address1-input"
                        onChange={handleChange("billing_address_country")}
                        label="Pays"
                        value={values.billing_address_country}
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />
                </Grid>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Moyen de paiement
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container
                justify={"center"}
                direction={"column"}>
                    <form>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={paymentMeans==='online'}
                                    onChange={()=>handleChangePaiementWay("online")}
                                    value="checkedA"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2" color="textSecondary" component="p" >
                                    Payer en ligne
                                </Typography>
                            }
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={paymentMeans==='virement'}
                                    onChange={()=>handleChangePaiementWay("virement")}
                                    value="checkedA"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2" color="textSecondary" component="p" >
                                    Payer par virement bancaire
                                </Typography>
                            }
                        />

                        <Divider className={classes.padded}/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cgAcceptance}
                                    onChange={()=>setCgAcceptance(!cgAcceptance)}
                                    value="checkedA"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2" color="textSecondary" component="p" >
                                    J'accepte les conditions d'utilisation et la politique de gestion des données de COD asbl.
                                </Typography>
                            }
                        />
                        <Divider className={classes.padded}/>

                    </form>


                    <Typography variant="body2" color="textSecondary" component="p" >
                        {paymentMeans==='virement' && (
                            <p>Nous allons générer un identifiant de transaction et vous fournir la communication à indiquer lorsque vous aurez confirmé la transaction</p>
                        )}
                        {paymentMeans==='online' && (
                            <p>Nous allons vous rediriger vers la solution de payement en ligne de BNP Paribas Fortis. <br/>
                                Votre réservation sera confirmée aussitôt que la banque nous aura confirmé le payement
                            </p>
                        )}
                    </Typography>
                </Grid>

                <Grid container
                      direction="row"
                      justify="space-between">
                    <Button  variant={"contained"}  color="primary" onClick={props.onClose} >
                        Annuler
                    </Button>
                    <Button  variant={"contained"}  color="primary" onClick={handlePaid} >
                        Payer
                    </Button>
                </Grid>
                </Container>

            </DialogContent>
        </Dialog>
    );
}

export default ConfirmationPayementModal;
