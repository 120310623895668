import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {Button, Paper} from "@material-ui/core";
import Title from "../Shared/Title";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Footer from "../Footer/Footer";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import config from "../../config/api"
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),

    },
    paddedRight : {
        paddingLeft : "30px"
    }
}));

export const Jod = (props)=>{
    const classes = useStyles();
    const [jod, setJod] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        (async ()=>{
            try{
                const {data}=await axios.get(config.api+"jod");
                console.log(data)
                setJod(data);
                setIsLoading(false);
            }catch (e) {
                console.log(e)
            }

        })();
    },[]);

    if(isLoading){
        return (
            <Container className={classes.progress}>

                <CircularProgress  />

            </Container>
        );
    }

    return (
        <>
            <Title title={"JOD"}/>
            <Container style={{minHeight: "100vh"}}>
                <Paper className={classes.root}>
                    <Grid container direction="row"
                    justify="flex-start">
                        {jod.map(((obj, index) => {
                            return (
                                <Grid item xs={6} sm={4} md={2}>
                                    <Button data-toggle="tooltip" title={"JOD "+(index +1)} onClick={()=>window.open(config.server+obj.url, '_blank')}>
                                        <img
                                            style={{
                                                maxWidth : "70px"
                                            }}
                                            src={config.server+obj.thumbNailUrl} alt={"url"+index}/>
                                        {"JOD "+(index +1)}
                                    </Button>
                                </Grid>
                            )
                        }))}
                    </Grid>
                </Paper>
            </Container>
            <Footer position="relative" marginTop="30px" {...props}/>

        </>
    );
};
