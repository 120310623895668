import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import config from "../../../config/api"
import {UserContext} from "../../../context/user-context";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(theme=>({

    appBar: {
        position: 'top',
    },
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height : "100%"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
    duotextFiedl:{
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        width : "80%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Prompter = (props)=> {

    const classes = useStyles();
    const [toAddAssistante, setToAddAssistante] = useState({
        firstName: "",
        lastName: "",
        id :""
    });
    const {user} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    const [addingAssistante, setAddingAssistante]=useState(true);

    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });

    useEffect(()=>{
        if(props.assistantesList.length===0){
            setAddingAssistante(false);
        }
    },[props]);

    useEffect(()=>{
        setToAddAssistante({
            firstName: "",
            lastName: "",
            id :""
        })
    }, [addingAssistante])

    const handleChange = name => event => {
        setToAddAssistante({...toAddAssistante, [name]: event.target.value})
    };


    const handleCloseInfos = () => {
        if (!toAddAssistante.id) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: "vous devez choisir une assistante"
            });
            return;
        }

        props.onClose(toAddAssistante);
        setToAddAssistante({
            firstName: "",
            lastName: ""
        });
    };


    const saveAssistanteAndClose = async ()=>{
        if (toAddAssistante.lastName.trim() === "" || toAddAssistante.firstName.trim() === "") {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: "vous devez mentionner un nom et un prénom"
            });
            return;
        }

        try{
            setIsLoading(true);
            const {data} = await axios.post(config.api+"assistantes",toAddAssistante,{
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            });

            setIsLoading(false);
            props.onClose(data, true);


        }catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
            setIsLoading(false);

        }

    };

    const cancelAndClose = () => {
        props.onClose(undefined);
    };

    return(
        <Dialog fullScreen fullWidth open={props.open} TransitionComponent={Transition}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
            <DialogContent className={classes.container}>


                {addingAssistante &&
                (<>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}>
                                    Complétez le nom et prénom de l’assistante
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <form>

                            <Grid container
                                  justify="flex-start"
                                  direction="column">

                                <TextField
                                    required
                                    id="filled-email-input"
                                    onChange={handleChange("firstName")}
                                    label="Prénom"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    variant="filled"
                                />
                                <TextField
                                    required
                                    id="filled-email-input"
                                    onChange={handleChange("lastName")}
                                    label="Nom"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    variant="filled"
                                />
                                <Button    color="primary"  onClick={()=>setAddingAssistante(false)}>
                                    Voir la liste
                                </Button>
                                <Grid container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                >
                                    <Button  variant={"contained"}  color="primary" onClick={cancelAndClose} >
                                        Annuler
                                    </Button>
                                    <Button  variant={"contained"}  color="primary" onClick={saveAssistanteAndClose} disabled={isLoading}>
                                        Ok
                                        {isLoading && <CircularProgress color="inherit" className={classes.rightIcon} />}
                                    </Button>


                                </Grid>

                            </Grid>
                        </form>
                    </>
                )
                }
                {!addingAssistante &&
                (
                    <>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}>
                                    Choisissez une assistante
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <form className={classes.root} autoComplete="off" >
                            <Grid container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="flex-start"
                            >
                                <Grid item
                                      lg={12}>
                                    <FormControl className={classes.formControl} >
                                        <InputLabel>Assistantes déjà enregistées: </InputLabel>
                                        <Select
                                            value={toAddAssistante}
                                            onChange={(event)=>{setToAddAssistante(event.target.value)}}
                                        >
                                            {props.assistantesList.map((ass,index) => {
                                                return <MenuItem key={ass.id} selected={()=>index===0} value={ass}>{ass.firstName +" " +ass.lastName}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                            <Button    color="primary"  onClick={()=>setAddingAssistante(true)}>
                                Pas dans la liste?
                            </Button>

                            <Grid container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="flex-start"
                            >
                                <Button  variant={"contained"}  color="primary" onClick={cancelAndClose} >
                                    Annuler
                                </Button>
                                <Button  variant={"contained"}  color="primary" onClick={handleCloseInfos} >
                                    Ok
                                </Button>
                            </Grid>
                        </form>

                    </>
                )
                }

            </DialogContent>
        </Dialog>
    );
}

export default Prompter
