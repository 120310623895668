import React, {useContext, useEffect,useState} from 'react';
import AnnoncesAdminTable from "./AnnoncesAdminTable";
import {UserContext} from "../../../context/user-context";
import config from "../../../config/api";
import axios from "axios";
import AnnoncesAdminAdd from "./AnnoncesAdminAdd";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
export default function AnnoncesAdministration() {

    const {user} = useContext(UserContext);
    const [annoncesList, setAnnoncesList] = useState({criteria:[], listAnnonces:[]});
    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });
    useEffect(()=>{
        (async ()=>{
            const {data} = await axios.get(config.api+"annonces");
            setAnnoncesList(data);
        })();
    },[]);


    const deleteOne = async (id)=>{
        try{
            const {data} = await axios.delete(config.api+"annonces?id="+id, {
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            });
            const updated = annoncesList.listAnnonces.filter(a => {
                return a.id !== id;
            });
            setAnnoncesList({...annoncesList, listAnnonces: updated});
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "supprimé"
            })
        }catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            })
        }
    };

    const publish = async (values)=>{
        try{
            const {data} = await axios.post(config.api+"annonces",values, {
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            });
            const updated = [...annoncesList.listAnnonces, values];
            setAnnoncesList({...annoncesList, listAnnonces: updated});
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "ajouté"
            })
        }catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            })
        }
    };



    return(
        <>
            <AnnoncesAdminAdd criteria={annoncesList.criteria} publish={publish}/>
            <AnnoncesAdminTable annoncesList={annoncesList.listAnnonces} onDelete={deleteOne}/>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
        </>
    )
}
