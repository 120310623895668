import React, {useContext, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import CardFormation from "../../../Formations/CardFormation";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../../Shared/ConfirmDialog";
import config from "../../../../config/api";
import axios from "axios";
import {UserContext} from "../../../../context/user-context";

const FormationsDeleteContainer = ({formationList, setFormationList, setSnackBarDisplay}) =>{

    const [open, setOpen]= useState(false);
    const [toDelete, setToDelete]= useState({});
    const {user} = useContext(UserContext);


    const handleClose = async (response)=>{
        setOpen(false);
            if(!response){
                return;
            }
            try{
                const{data}= await axios.delete(config.api+"formations?id="+toDelete.id,
                    {
                        headers : {
                            "authorization": "Bearer " + user.accessToken
                        }
                    });
                setFormationList(formationList.filter(f=>f.id!==toDelete.id));
                setSnackBarDisplay({
                    open: true,
                    variant: "success",
                    message: "supprimé"

                });
            }catch (e) {
                setSnackBarDisplay({
                    open: true,
                    variant: "error",
                    message: e.response.data.message

                });
            }
    };



    return(
        <>
            <ConfirmDialog
                handleClose={handleClose}
                open={open}
                title="ATTENTION /!\ Confirmer la suppression"
                text="La suppression d'une formation est dangereuse si certaines personnes l'ont déjà réservée et/ou payée.
                La suppression d'une formation détruit irrémédiablement le lien existant entre un utilisateur et cette formation.
                <br/>
                       <br/>
                 Cette option ne devrait être utilisée qu'en fin d'année, une fois que toutes les formations sont cloturées
                <br/>       <br/>

                   <h4>Ne supprimez une formation que si vous êtes sûr de savoir ce que vous faites</h4>"
            />
                <Grid container
                      justify={"space-between"}
                      style={{marginTop : "20px"}}>


                    {formationList.map(f => {
                        return(
                            <Grid item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  key={f.id}
                                  style={{ overflow : "hidden", paddingBottom :"50px"}}
>

                                <Button  variant="contained" color="secondary"
                                        onClick={()=>{
                                            setToDelete(f)
                                            setOpen(true)
                                        }}>
                                    Supprimer
                                </Button>
                                <CardFormation
                                    disableReservation
                                    formation={f}
                                />

                            </Grid>
                        )
                    })}

                </Grid>


        </>

    )


};


export default FormationsDeleteContainer;
