import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SearchBar({callBack, criteria}) {
    const classes = useStyles();
    const [values, setValues] = React.useState("Tout");


    function  handleChange(event) {
        setValues(event.target.value);
        callBack(event.target.value);
    }



    return (
        <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
                <InputLabel>Trier par : </InputLabel>
                <Select
                    value={values}
                    onChange={handleChange}
                >
                    <MenuItem key={"tout"} value="Tout" >Tout</MenuItem>
                    {criteria.map(crit => {
                       return <MenuItem key={crit.type} value={crit.type}>{crit.displayName}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </form>
    );
}
