import React, {useContext, useState} from "react";
import {Container, makeStyles} from "@material-ui/core";
import {UserContext} from "../../../context/user-context";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {KeyboardDatePicker} from '@material-ui/pickers';

const useStyles = makeStyles(theme=>({
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height : "100%"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "120px",
    },
    dense: {
        marginTop: 16,
    },

}));
export default function AnnoncesAdminAdd ({criteria, publish}){
    const classes = useStyles();
    const {user} = useContext(UserContext);
    const [values, setValues] = React.useState({
        publicationDate: new Date(),
        phone: '',
        mail :'',
        type:'',
        description:''
    });

//modifie les state contenant les infos utilisateurs
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    const handleChangeDate = date => {
        setValues({...values,publicationDate: date });
    };


    return (

        <Container>

            <Grid container
                  direction="row"
                  justify="space-between"
            >

                <Grid item xs={12} md={3}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date picker inline"
                        value={values.publicationDate}
                        onChange={handleChangeDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <form>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Type </InputLabel>
                        <Select
                            value={values.type}
                            onChange={handleChange("type")}
                        >
                            {criteria.map(crit => {
                                return <MenuItem key={crit.type} value={crit.type}>{crit.displayName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    </form>
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        id="filled-name-input"
                        onChange={handleChange("phone")}
                        value={values.phone}
                        label="Tel"
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />

                </Grid>


                <Grid item xs={12} md={3}>
                    <TextField
                        id="filled-name-input"
                        onChange={handleChange("mail")}
                        value={values.mail}
                        label="mail"
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                    />
                </Grid>


            </Grid>
            <Grid container
                  >
                <Grid item xs={12} md={12}>
                    <TextField
                        id="filled-name-input"
                        onChange={handleChange("description")}
                        value={values.description}
                        label="description"
                        type="text"
                        autoComplete=""
                        margin="normal"
                        variant="filled"
                        style={{width: "100%"}}
                    />
                </Grid>
            </Grid>


            <Grid container
                  direction="row"
                  justify="flex-end"
            >
                <Grid item xs={2} className={classes.dense}>
                    <Button  variant={"contained"}  color="primary" onClick={()=>{publish(values)}}>
                        Ajouter
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.dense}/>
                </Grid>
            </Grid>
        </Container>



    )
};

