import React, {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../../context/user-context";
import JodView from "./JodView";
import axios from "axios";
import config from "../../../config/api"

const JodContainer = props => {


    const {user}=useContext(UserContext);
    const [jodNumber, setJodNumber]=useState(0);
    const thumbnailFile=useRef(null);
    const pdfFile=useRef(null);
    const [loading, setLoading]=useState(true);
    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });

    useEffect(()=>{
        (async()=>{
            try{
                const {data}=await axios.get(config.api+"jod/last");
                if(!data[0]){
                    setJodNumber(1);
                    setLoading(false);
                    return ;
                }
                setJodNumber(data[0].number +1);
                setLoading(false);
            }catch (e) {
                console.log(e)
            }

        })();
    },[]);


    const postJod = async ()=>{

        const formData = new FormData();
        formData.append('pdf',pdfFile.current.files[0]);
        formData.append('thumbnail',thumbnailFile.current.files[0]);
        formData.append('number',jodNumber+"");
        try{
            await axios.post(config.api+"jod", formData, {
                headers : {
                    'content-type': 'multipart/form-data',
                    'authorization': 'Bearer '+user.accessToken
                }
            });
            setSnackBarDisplay(
                {
                    open: true,
                    variant: "success",
                    message: "JOD ajouté"
                }
            )
        }catch (e) {
            setSnackBarDisplay(
                {
                    open: true,
                    variant: "error",
                    message: e.message
                }
            )
        }
    };


    return (
        <JodView
            postJod={postJod}
            loading={loading}

            jodNumber={jodNumber}
            setJodNumber={setJodNumber}

            snackBarDisplay={snackBarDisplay}
            setSnackBarDisplay={setSnackBarDisplay}
            ref={{
                refThumb: thumbnailFile,
                refPdf: pdfFile
            }}
        />
    );
};


export default JodContainer;
