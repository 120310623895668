import React, {useEffect, useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import CardFormation from "../../../../Formations/CardFormation";
import config from "../../../../../config/api";
import axios from "axios";

const FormationsSection = (props) => {

    const [formations, setFormations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=> {
        (async () => {
            const {data} =await axios.get(config.api+"formations?limit=3&order=ASC");
            console.log(data)
            if(data.formations.length>0){
                const toDisplay = data.formations.filter(f=>!!f);
                setFormations(toDisplay);
            }
            setIsLoading(false)
        })();
    }, []);
    if(isLoading){
        return (
            <CircularProgress />
        );
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"

            component={"div"}
            style={{
                paddingTop : "100px",
                paddingBottom : "100px",
                paddingLeft : "10%",
                paddingRight : "10%",
                backgroundColor:"#3a405a",

            }}
        >

            {formations.map((f, index)=>{

                return(
                    <Grid
                        key={f.id}
                        item
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        component={"div"}
                    style={{
                        paddingTop : "10px"
                    }}>
                        <CardFormation
                            key={f.id}
                            formation={f}
                        />
                    </Grid>
                );
            })}
        </Grid>

    );

};


export default FormationsSection;
