import React, {useContext, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Annonce from "./Annonce";
import {Container, makeStyles} from "@material-ui/core";
import SearchBar from "./SearchBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

import Divider from "@material-ui/core/Divider";
import Title from "../Shared/Title";
import Footer from "../Footer/Footer";
import config from "../../config/api";
import {UserContext} from "../../context/user-context";
const AnnoncesList = (props)=>{
    const useStyles = makeStyles(theme => ({
        root: {
            minHeight: "100vh"
        },
        progress: {
            margin: theme.spacing(2),
            height : "100vh"

        },
        marged:{
            marginBottom :10,
            marginTop : 10
        }
    }));
    const classes = useStyles();

    //getAnnonces online
    //{annonces.listAnnonces => list d'annonces, annonces.listCriteria => list de critère)
    const [annonces, setAnnonces] = useState({listAnnonces:[], criteria:[]});
    const [displayAnnonces, setDisplayAnnonces] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        (async ()=>{
            try{
                const {data} = await axios.get(config.api+"annonces");
                console.log(data)
                setAnnonces(data);
                setDisplayAnnonces(data.listAnnonces);
                setIsLoading(false);
            }catch (e) {
                console.log(e)
            }

        })();
    }, []);

    const handleSorting = (criterium) =>{
        if(criterium==="Tout"){
            setDisplayAnnonces(annonces.listAnnonces);
            return;
        }
        const sorted = annonces.listAnnonces.filter(value => {
            return value.type === criterium;
        });
        setDisplayAnnonces(sorted);
    };

    if(isLoading){
        return (
            <Container className={classes.progress} >
                <CircularProgress />
            </Container>
        );
    }
    return(
        <>
            <Title title={"Petites annonces"}/>
            <Container className={classes.root}>
            <Grid item lg={12}>

                <SearchBar
                    callBack={handleSorting}
                    criteria={annonces.criteria}
                />

            </Grid>
            <Divider className={classes.marged}/>

            <Grid container
                  direction="row"
                  justify="flex-start"
                  spacing={4}
            >
                {displayAnnonces.map((a)=>{
                    return(<Grid item lg={3} md={4} sm={6} xs={12} key={a.id}>
                        <Annonce
                            data={a}/>
                    </Grid>);
                })}


            </Grid>
            </Container>
            <Footer position="relative" marginTop="30px" {...props}/>

        </>
    );

};

export default AnnoncesList;
