import React, {useContext, useEffect, useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import DropDownMenu from "./DropDownMenu";
import { withRouter } from "react-router-dom";
import ConnexionPopUp from "../Connection/ConnexionPopUp";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Badge from "@material-ui/core/Badge";
import {CssBaseline} from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import * as PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Divider from "@material-ui/core/Divider";
import miniLogo from "../../assets/cod-logo-noText.svg"
import {UserContext} from "../../context/user-context";
import axios from "axios";
import config from "../../config/api"
const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 10000,
    },
    menuButton: {
        [theme.breakpoints.down("md")] : {
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
            display: "block"

        },
        display: "none"
    },
    title: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
    wrapper: {
        [theme.breakpoints.down("md")] : {
            display: "none",
            flexDirection : "column"
        },

            display: "flex",
            flexDirection : "row",
            justifyContent : "space-between"

    },
    listContainer: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        overflow: "hidden"
    },
    listMenus: {
        float: "left"
    },
    listItems: {
        display: "inline-block",
        textAlign: "center",
        padding: "6px 8px"
    },
    miniLogo: {
        height : "48px",
        paddingRight : "10px"

    }
}));


function ScrollTop(props) {
    const { children } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = event => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
};

const NavBar = withRouter((props) => {

    const classes = useStyles();
    const [connexionPopUpOpen, setConnexionPopUpOpen] = useState(false);
    const {user, ejectUser,userReservation}= useContext(UserContext);
    const [countPanier, setCountPanier] = useState(0);
    const [lastJod, setLastJod]=useState("");

    const handleSignOut =async ()=>{
        await ejectUser();
    };
    const handleClosePopUp = () =>{
        setConnexionPopUpOpen(false);
    };
    const handleConnexionPopUp=()=>{
        setConnexionPopUpOpen(true);
    };

    useEffect(()=>{
        (async () => {
            try{
                const {data}=await axios.get(config.api+"jod/last");
                setLastJod(config.server+data[0].url);
            }catch (e) {
                console.log("plantage réseau")
            }
        })();

    },[]);

    useEffect(()=>{
        const count = userReservation.reservationsList.filter(res=> {
            return res.status==="panier";
        }).length;

        setCountPanier(count);
    }, [userReservation]);

    return (

        <>
            <CssBaseline />
            <AppBar position="sticky" color="primary" >
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={()=>{
                    const current =  document.getElementById("toolbar").style.display;
                    document.getElementById("toolbar").style.display = (current==="flex")? "none" : "flex";
                }}>
                    <MenuIcon />
                </IconButton>
                <Toolbar className={classes.wrapper} id={"toolbar"} >
                    <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={()=>props.history.push("/")}>
                        <img src={miniLogo} alt={"miniLogo"} className={classes.miniLogo}/>
                        Accueil
                    </Button>
                    <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={()=>props.history.push("/formations")}>
                        Les Formations
                    </Button>
                        <DropDownMenu
                            title={"Les JOD"}
                            content={[
                                {
                                    displayName : "Dernier Paru",
                                    link : ()=>window.open(lastJod, '_blank')
                                },
                                {
                                    displayName : "Historique des JOD",
                                    link : "jod"
                                }
                            ]}/>
                        <DropDownMenu
                            title={"Infos Utiles"}
                            content={[
                                {
                                    displayName : "Accréditation",
                                    link : "accreditations"
                                },
                                {
                                    displayName : "Agrément",
                                    link : "agrement"
                                },
                                {
                                    displayName : "Conditions générales",
                                    link : "conditions"
                                },
                                {
                                    displayName : "Politique de protection des données",
                                    link : "protection"
                                }
                            ]}/>
                        <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={()=>props.history.push("/annonces")}>
                            Petites annonces
                        </Button>
                        <Divider orientation="vertical" light={false} style={{width : "5px"}}/>
                    {user.accessToken && (
                        <Button className={classes.login} color="inherit"  onClick={()=>props.history.push("/profil")}>
                            Profil
                            <Badge className={classes.padding} badgeContent={countPanier} color="secondary">
                                <ShoppingBasketIcon />
                            </Badge>
                        </Button>)}
                    {(user.accessToken && user.isAdmin)&&(<Button className={classes.login} color="inherit" onClick={()=>props.history.push("/admin")}>Administration </Button>)}

                    {!user.accessToken && (<Button className={classes.login} color="inherit" onClick={handleConnexionPopUp}>connexion</Button>)}
                    {user.accessToken && (<Button className={classes.login} color="inherit" onClick={handleSignOut}>Déconnection </Button>)}
                </Toolbar>
            </AppBar>
            <div id="back-to-top-anchor" />
            {connexionPopUpOpen && (<ConnexionPopUp closeCallBack={handleClosePopUp}/>)}
            <ScrollTop {...props}>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    );
});

export default NavBar;
