import React from "react";
import Title from "../Shared/Title";
import {Container, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Footer from "../Footer/Footer";
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),

    },
    paddedRight : {
        paddingLeft : "30px"
    }
}));
function ConditionGen(props) {
    const classes = useStyles();
    return(
        <>
            <Title title={"Conditions générales"}/>
            <Container maxWidth="lg"  >

                <Paper className={classes.root}>
                    {/*
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    DESCRIPTION
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    */}
                    <Typography component="p" variant="h5" color="textSecondary"  >
                        Article 1. Descriptions
                    </Typography>
                    <br/>
                    <Typography component="div" variant="p" color="textPrimary" >
                       A. Le Collège d’Omnipratique Dentaire Asbl (C.O.D. Asbl)
                        <ul>
                            <li>
                                est une Association sans But Lucratif
                            </li>
                            <li>
                                enregistrée à la BCE sous le numéro 0474.620.109
                            </li>
                            <li>
                                son siège social : rue Neuve 59 à 6042 Lodelinsart,
                            </li>
                            <li>
                                mail :<a href="mailto:info@cod.eu.com">info@cod.eu.com</a>.
                            </li>
                            <li>
                                tel : +32 473 715 714
                            </li>
                            <li>
                                fax : +32 71 333 805
                            </li>
                            <li>
                                compte : BE 32 0013 5455 6702
                            </li>

                        </ul>
                    </Typography>
                    <Typography component="p" variant="p" color="textPrimary" >
                        B. Les formations, organisées par le C.O.D. Asbl, sont proposées aux
                        dentistes et aux assistantes dentaires dans le cadre de la formation
                        continue obligatoire pour les praticiens de l’Art Dentaire en Belgique.
                    </Typography>
                    <br/>

                    <Typography component="p" variant="p" color="textPrimary" >
                        C. Les différentes formations proposées sont dénommées en 5 catégories :
                        <ul>
                            <li>
                                1/ FC - les Formations Continues
                            </li>
                            <li>
                                2/ PR - les Peer-Review
                            </li>
                            <li>
                                3/ RX - la Radioprotection
                            </li>
                            <li>
                                4/ TP - Travaux Pratiques
                            </li>
                            <li>
                                5/ EN - Ennéagramme
                            </li>
                        </ul>
                    </Typography>
                    <Typography component="p" variant="p" color="textPrimary" >

                       D. Pour le suivi chronologique, chaque formation est appelée par ses initiales suivi d’un chiffre
                        constant durant l’année civile et la numérotation est réinitialisée début janvier lors de la
                        présentation du programme de l'année civile.
                    </Typography>
                    <br/>


                    <Typography component="p" variant="p" color="textPrimary" >
                        E. Le cas particulier du Peer Review :
                        <ul>
                            <li>
                                1. La participation à un Peer-Review engage le Praticien de l’Art Dentaire
                                à respecter le règlement de l’Accréditation des Praticiens de l’Art Dentaire, en
                                particulier la règle qui impose 8 participants minimum et 20 participants
                                maximum. Aucune dérogation n’est possible à ce règlement.
                            </li>
                            <li>
                                2. L’inscription aux Peer-Review du COD Asbl impose la présence à 2
                                Peer-Review consécutifs.
                            </li>
                            <li>
                                3. Le participant fait mention de ses préférences sur le formulaire
                                d’inscription mais il est possible qu’il soit affecté à un autre groupe si le
                                groupe choisi est complet. Cette nouvelle affectation est sans appel.
                            </li>
                        </ul>
                    </Typography>
                    <br/>
                    {/*
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    Conditions d’inscription aux formations organisées
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    */}
                    <Typography component="p" variant="h5" color="textSecondary"  >
                       Article 2. Conditions d’inscription aux formations
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        A. Les inscriptions sont strictement nominatives et ne peuvent faire l’objet de cession entre personnes.
                    </Typography>
                    <br/>

                    <Typography component="p" variant="p" color="textPrimary" >
                        B. Une procédure d’inscription intuitive et complète est disponible sur le site
                        internet <a href="www.cod.eu.com">cod.eu.com</a> avec intégration d’un module de payement en ligne
                        sécurisé selon la solution E-paiement de Saferpay, « ce qui garantit le niveau optimal
                        de sécurité technique des paiements grâce aux toutes dernières technologies de
                        cryptage ». L’inscription via la procédure en ligne est recommandée.
                    </Typography>
                    <br/>

                    <Typography component="p" variant="p" color="textPrimary" >
                        C. L’inscription en ligne sur le site <a href="www.cod.eu.com">cod.eu.com</a> débute par l’identification
                        de la personne et la création de son profil.
                        <ul>
                            <li>
                                1. Les données suivantes sont indispensables : nom, prénom, adresse
                                mail valide et numéro INAMI.
                            </li>
                            <li>
                                2. L’adresse mail doit être validée une seule fois et un mot de passe doit
                                être choisi pour sécuriser le profil.
                            </li>
                            <li>
                                3. Chaque personne qui a créé un profil est responsable de l’utilisation de
                                l’accès à son profil via son adresse mail et son mot de passe.
                            </li>
                            <li>
                                4. La collecte de vos données répond aux exigences de loi sur protection
                                de la vie privée RGPD (voir notre politique de protection des données)
                            </li>
                            <li>
                                5. Dans le « profil », l’onglet « réservations » permet la vue globale de
                                toutes les inscriptions aux activités du C.O.D. Asbl de l’année en cours.
                            </li>
                            <li>
                                6. L’adresse mail choisie pour la création du profil sera considérée comme étant la
                                voie de communication préférentielle avec le C.O.D. Asbl. sauf avis contraire signalé
                                lors de la réservation par mail à <a href="mailto:info@cod.eu.com">info@cod.eu.com</a>.
                            </li>
                        </ul>

                        D. Les inscriptions par courrier, fax ou mail ne sont effectives qu’après réception
                        du formulaire d’inscription entièrement et lisiblement complété. Le C.O.D. Asbl ne
                        peut garantir le traitement de formulaires illisibles ou incomplets.
                        Le formulaire d’inscription entièrement et lisiblement complété doit être envoyé
                        <ul>
                            <li>
                               1. soit par courrier à l’adresse du siège administratif du C.O.D. Asbl, rue Neuve 59 à 6042 Lodelinsart
                            </li>
                            <li>
                               2. soit par fax au 071/33.38.05.
                            </li>
                            <li>
                               3. soit par mail à <a href="mailto:info@cod.eu.com">info@cod.eu.com</a>
                            </li>
                        </ul>
                        <Typography component="p" variant="p" color="textPrimary" >
                            E. Attention les réservations ne seront confirmées qu’après réception du montant de exact de l’inscription.
                        </Typography>
                        <br/>
                        <Typography component="p" variant="p" color="textPrimary" >
                            F. Seul le paiement valide l’inscription, paiement dont la date-valeur fait foi pour
                            l’attribution des places disponibles.
                        </Typography>
                        <br/>


                        <Typography component="p" variant="p" color="textPrimary" >
                            G. Des réductions sont accordées aux participants à certaines conditions :
                        </Typography>
                        <ul>
                            <li>
                                1. La réduction fidélité est accordée partir de 3 inscriptions sur l’année à
                                des Formations Continues.
                            </li>
                            <li>
                                2. L’inscription complémentaire à des Travaux Pratiques, des Peer-
                                Review ou des journées de Radioprotection n’entrent pas en ligne de compte
                                pour le calcul de cette réduction-fidélité.
                            </li>
                            <li>
                                3. Le montant de cette réduction-fidélité est clairement indiqué sur le
                                formulaire d’inscription.
                            </li>
                            <li>
                                4. Le C.O.D. Asbl accorde aussi des réductions pour les jeunes diplômés,
                                les étudiants et les assistantes dentaires. Le montant de ces réductions est
                                clairement indiqué sur le formulaire d’inscription.                            </li>
                            <li>
                                5. Les réductions « jeunes diplômés » sont accordées sur base de l’année
                                mentionnée au n° INAMI.
                            </li>
                            <li>
                                6. Pour les étudiants bénéficiant de la réduction « Master 1 ou 2 », la
                                présentation de la carte d’étudiant pourra vous être réclamée à l’accueil de
                                l’activité.
                            </li>
                            <li>
                                7. Pour les assistantes dentaires, la réduction est octroyée que si elles
                                (ils) accompagnent un dentiste inscrit à l’activité.
                            </li>
                        </ul>
                    </Typography>
                    <br/>
                    {/*
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                        Paiement et annulation
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    */}
                    <Typography component="p" variant="h4" color="textSecondary"  >
                        Article 3. Paiement
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        A. Les réservations payées sont confirmées par le C.O.D. Asbl par l’envoi
                        d’une lettre de confirmation d’inscription et d’un reçu fiscal.
                        Il est important de vérifier cette confirmation car il appartient au participant de se
                        manifester auprès du C.O.D. Asbl si cette confirmation comprend des erreurs.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        B. L’inscription n’est validée qu’à la réception du paiement.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        C. Le paiement se fera de préférence au moment de l’inscription et au plus tard
                        40 jours avant l’activité concernée.
                    </Typography>
                    <br/>

                    <Typography component="p" variant="p" color="textPrimary" >
                        D. Une demande d’inscription et un paiement tardifs, à moins de 7 jours avant
                        l’activité, doit absolument être envoyée et confirmée par mail à info@cod.eu.com. Il
                        sera demandé une preuve de paiement à l’accueil. Sans celle-ci, l’accès à l’activité
                        pourra être refusé.
                    </Typography>
            <br/>
                    {/*
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                        Conditions d’annulation
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    */}
                    <Typography component="p" variant="h5" color="textSecondary"  >
                        Article 4. Annulation et conditions d’annulation
                    </Typography>
                    <br/>

                    <Typography component="p" variant="p" color="textPrimary"  >
                        A. Toute demande d’annulation d’une inscription validée et confirmée doit être formulée par écrit à l’adresse mail
                        info@cod.eu.com.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary"  >
                        B. Aucune annulation n’est possible pour les Peer-Review car la présence
                        effective de chaque participant est obligatoire pour garantir la validité de
                        l’accréditation de cette activité.
                    </Typography>
                    <br/>

                    {/*
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                                                        Litiges
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    */}
                    <Typography component="p" variant="h5" color="textSecondary"  >
                        Article 5. Litiges
                    </Typography>
                    <br/>

                    <Typography component="p" variant="p" color="textPrimary"  >
                        A. La plateforme en ligne de la Commission Européenne pour la résolution des
                        litiges de consommation peut être consultée au <a href={"http://ec.europa.eu/odr/"}>
                        http://ec.europa.eu/odr/</a>

                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary"  >
                        B. En cas de litige non résolu de façon amiable, les tribunaux de Charleroi seront
                        seuls compétents.
                    </Typography>
                    <br/>


                    {/*
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                        Droit à l’accréditation des Praticiens de l’Art Dentaire
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    */}
                    <Typography component="p" variant="h4" color="textSecondary"  >
                        Article 6. Droit à l’accréditation des Praticiens de l’Art Dentaire
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        A. Le participant est tenu de respecter scrupuleusement les horaires mentionnés
                        sur la confirmation qui lui est envoyée.
                    </Typography>
                    <br/>

                    <Typography component="p" variant="p" color="textPrimary" >
                        B. Le C.O.D. Asbl est un organisateur agréé sous le n° 125 pour
                        l’accréditation des Praticiens de l’Art dentaire en Belgique.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        C. Le C.O.D. Asbl ne peut être tenu responsable de décisions ou de délais
                        qui ne lui appartiennent pas. La mention “accréditation demandée” ne
                        garantit jamais la décision finale du Groupe de Direction de
                        l’Accréditation.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        D. Le participant qui s’inscrit à une formation du C.O.D. Asbl s’engage à
                        respecter le règlement de l’accréditation des Praticiens de l’Art dentaire
                        en Belgique, à savoir qu’une arrivée tardive ou un départ prématuré lors
                        d’une journée de formation reconnue lui enlèvent automatiquement le
                        droit à cette accréditation sans que le C.O.D. Asbl ne puisse en être tenu
                        responsable.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="p" color="textPrimary" >
                        E. Le C.O.D. Asbl ne peut garantir le traitement de formulaires illisibles ou
                        incomplets.
                    </Typography>
                    <br/>
                    {/*
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                    ================================================================================================
                                                Propriété intell
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    ===============================================================================================
                    */}
                    <Typography component="p" variant="h4" color="textSecondary"  >
                        Article 7. Propriété intellectuelle
                    </Typography>
                    <br/>


                    <Typography component="p" variant="p" color="textPrimary" >
                    A. Toutes les données de quelque nature qu’elles soient, textes, graphismes,
                    photos, logos, qu’ils soient déposés ou non,... figurant sur le site <a href="www.cod.eu.com">cod.eu.com</a>, le
                    Journal d’Omnipratique Dentaire (JOD) ou les dias de cours sont nécessairement
                    protégés par les droits d’auteur et tous autres droits de propriété intellectuelle. A ce
                    titre, seule l’utilisation à titre privé est autorisée
                    </Typography>
                    <Grid container direction="row" justify="flex-end" >
                        <Typography component="p" variant="p" color="textPrimary" >
                            Dernière mise à jour : 07/07/2020
                        </Typography>
                    </Grid>

                </Paper>
            </Container>
            <Footer position="relative" marginTop="30px" {...props}/>
        </>
    );
}

export default ConditionGen;
