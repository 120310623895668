import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import config from "../../config/api";
const UserContext = React.createContext(null);

const UserContextProvider = ({ children }) => {

    /*
    user : {
        isAdmin,
        accessToken,
        isMailChecked
    }
     */

    const [user, setUser] = useState({});
    const [userReservation, setUserReservation]=useState({reservationsList : []});

    const [isDentist, setIsDentist]=useState(true);



    //try to recover accesstoken from local storage and refresh it if necessary
    useEffect(()=>{
        (async ()=>{
            await refreshToken();
        })();
    },[]);

    useEffect(()=>{
        (async ()=>{

            if(!user.accessToken){
                return ;
            }

            const {data} = await axios.get(config.api+"reservations",{
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            });
            setUserReservation(data)

        })();
    }, [user]);


    const forceRefreshPanier = async ()=>{
        const {data} = await axios.get(config.api+"reservations",{
            headers : {
                "authorization" : "Bearer "+user.accessToken
            }
        });

        setUserReservation(data);
    };

    const refreshToken = async()=>{
        if(window.localStorage.getItem("session")){
            const {accessToken, isAdmin} = JSON.parse(window.localStorage.getItem("session"));
            if(accessToken){
                try{
                    const {data, status} = await axios.get(config.api+"refreshToken",{
                        headers : {
                            "Authorization" : "Bearer "+accessToken
                        }
                    });
                    setUser(data);
                    window.localStorage.setItem("session", JSON.stringify(data));
                }catch (e) {
                    window.localStorage.removeItem("session");
                }
            }
        }
    };

    const logIn = async(mail, password)=>{

            const {data, status} = await axios.post(config.api+"auth",
                {mail, password}
            );
            window.localStorage.setItem("session", JSON.stringify(data));
            setUser(data);
    };

    const signIn = async(userData)=>{
        const {data} = await axios.post(config.api+"users",userData);
        window.localStorage.setItem("session", JSON.stringify(data));
        setUser(data);
    };

    const ejectUser = async ()=>{
        setUser({});
        window.localStorage.removeItem("session");

    };

    const isLoggedIn=()=>{
        return !!user.accessToken
    };


    return (
        <UserContext.Provider value={{ forceRefreshPanier, logIn, user, setUser,signIn, ejectUser, refreshToken,userReservation, setUserReservation, isLoggedIn, isDentist, setIsDentist }}>
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { UserContext, UserContextProvider };
