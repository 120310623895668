import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route} from "react-router-dom";
import Landing from "../Landing/Landing";
import NavBar from "../Navbar/NavBar";
import AnnoncesList from "../Annonces/AnnoncesList";
import Agrement from "../Infos/Agrement";
import ConditionGen from "../Infos/ConditionGen";
import HubFormation from "../Formations/HubFormation";
import Accreditations from "../Infos/Accreditations";
import UserProfil from "../UserProfil/UserProfil";
import Adminstration from "../Adminstration/Adminstration";
import SuccessSaferPay from "../SaferPay/SuccessSaferPay";
import FailSaferPay from "../SaferPay/FailSaferPay";
import {createMuiTheme , MuiThemeProvider} from '@material-ui/core/styles';
import ProtectionDonnee from "../Infos/ProtectionDonnees";
import {Jod} from "../Jod/Jod";
import {UserContextProvider} from "../../context/user-context";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ReinitPassword from "../global/ReinitPassWord/ReinitPassword";
const theme = createMuiTheme({
    palette: {
        primary: {
            main : "#3a405a",
        },
        secondary: {
            main :"#A63A50"
        },
        tertiary: {
            main : "#6DA34D"
        },
        textPrimary: {
            main : "#3a405a"
        },
        textSecondary:{
            main: "#ffffff"
        }
    },

    status: {
        danger: 'orange',
    },
});

const  App =  () => {
    return (
            <UserContextProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={theme}>

            <div className="App" >
            <Router>
                <NavBar/>
                <Route path="/" exact component={Landing}/>
                <Route path="/annonces" exact component={AnnoncesList}/>
                <Route path="/agrement" exact component={Agrement}/>
                <Route path="/conditions" exact component={ConditionGen}/>
                <Route path="/formations" exact component={HubFormation}/>
                <Route path="/accreditations" exact component={Accreditations}/>
                <Route path="/profil" exact component={UserProfil}/>
                <Route path="/protection" exact component={ProtectionDonnee}/>
                <Route path="/admin" exact component={Adminstration}/>
                <Route path="/success/:tid" component={SuccessSaferPay}/>
                <Route path="/fail/:tid" component={FailSaferPay}/>
                <Route path="/jod" component={Jod}/>
                <Route path="/passwordInit/:rdStr" component={ReinitPassword}/>
            </Router>
            </div>
            </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </UserContextProvider>



    );
}

export default App;
