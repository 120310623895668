import React, {useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from "@material-ui/core/Container";
import VirementValidationModalSummary from "./VirementValidationModalSummary";
import {Button} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../../../config/api";
import axios from "axios";
import {UserContext} from "../../../../context/user-context";
const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
});


export default function VirementValidationTableResult({data, forceRefresh, updated}) {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [modalDisplay, setModalDisplay] = useState();
    const [validating, setValidating] = useState({});
    const {user} = useContext(UserContext);



    const handleValidation = async (value) => {
        setValidating({[value] : true});
        try{
            const {data} = await axios.post(config.api+"adminVirementValidation", {
                    transactionId : value
                },
                {
                    headers: {
                        "authorization": "Bearer " + user.accessToken
                    }
                });
            setValidating({[value] : false});
            updated(value, data);
        }catch (e) {
            console.log("error")
        }
    };



    return (
        <Container>
            <Paper className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Num de suivi</TableCell>
                            <TableCell align="left">Nom de facturation</TableCell>
                            <TableCell align="left">Date de commande</TableCell>
                            <TableCell align="left">Type de paiement</TableCell>
                            <TableCell align="left">Détail commande</TableCell>
                            <TableCell align="left">Valider le virement</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(data).map(k => (
                            <TableRow key={k}>
                                <TableCell  align="left" component="th" scope="row">
                                    {k}
                                </TableCell>
                                <TableCell align="left">{data[k][0].billing_Title}</TableCell>
                                <TableCell align="left">{new Date(data[k][0].pending_date).toLocaleString()}</TableCell>
                                <TableCell align="left">{data[k][0].payment_means}</TableCell>
                                <TableCell align="left">
                                    <Button  variant={"contained"}  color="primary" onClick={()=>{
                                        setModalDisplay(data[k]);
                                        setOpenModal(true);
                                    }}>
                                        Détails
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    <Button  variant={"contained"}  color="primary" onClick={()=>{handleValidation(k)}} disabled={data[k][0].status==='paid'}>
                                        {(!validating[k] && !(data[k][0].status==='paid')) && "Valider Paiement"}
                                        {validating[k] && <CircularProgress color="inherit" className={classes.rightIcon} />}
                                        {(!validating[k] && data[k][0].status==='paid') && "Validé" }
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {modalDisplay &&   <VirementValidationModalSummary open={openModal} reservations={modalDisplay} close={()=>setOpenModal(false)}/>}
        </Container>

    );
}
