import React, {useContext, useEffect, useState} from "react";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import FormationsWisardContainer from "./view/FormationsWisardContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import FormationsUpdateContainer from "./view/FormationUpdateContainer";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import FormationsDeleteContainer from "./view/FormationDeleteContainer";
import config from "../../../config/api";
import axios from "axios";
import {UserContext} from "../../../context/user-context";


const useStyles = makeStyles({
    root: {
        width: "100%",
    },
});

const FormationsContainer = props=>{


    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [formationType, setFormationType]=useState([]);
    const [conferenceRooms, setConferenceRooms]=useState([]);
    const [formationList, setFormationList]=useState([]);
    const [speakerList, setSpeakerList]=useState([]);
    const [loading, setLoading]=useState(true);
    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });


    const [newFormation, setNewFormation] = useState({});
    const [defaultFormation, setDefaultFormation]=useState({});
    const {user}=useContext(UserContext);

    useEffect(()=>{
        (async ()=>{

            try{
                const formationData = await axios.get(config.api+"formations");
                const sallesData = await axios.get(config.api+"salles");
                const speakersData = await axios.get(config.api+"speakers");
                setSpeakerList(speakersData.data);
                setConferenceRooms(sallesData.data);
                setFormationList(formationData.data.formations);
                setFormationType(formationData.data.formationsType);
                setNewFormation({
                    name : "",
                    type : formationData.data.formationsType[0].type,
                    Calendars:[
                        {id: -1, startDate :new Date(), stopDate:new Date()}
                    ],
                    ConferenceRooms:[sallesData.data[0]],
                    Speakers:[speakersData.data[0]],
                    Accreditations:[{ new: true, domaine:"", unit :"", numero:"" }]
                });
                setDefaultFormation({
                    name : "",
                    type : formationData.data.formationsType[0].type,
                    Calendars:[
                        {id: -1, startDate :new Date(), stopDate:new Date()}
                    ],
                    ConferenceRooms:[sallesData.data[0]],
                    Speakers:[speakersData.data[0]],
                    Accreditations:[{ new: true, domaine:"", unit :"", numero:"" }]
                })
                setLoading(false);

            }catch (e) {
                console.log(e)
            }
        })();
    },[]);

    const create =async (file) => {
        setLoading(true);
        try{
            const formData = new FormData();
            formData.append("illustration", file);
            formData.append('formation',JSON.stringify(newFormation));
            const {data} = await axios.post(config.api+"formations",formData,
                {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken,
                        'content-type': 'multipart/form-data',

                    }
                }
            );
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "Sauvegardé"
            });

            setFormationList([...formationList, data]);
            setNewFormation(defaultFormation);
            setLoading(false);

        }catch (e) {
            setLoading(false);

            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
        }
    };

    const pushUpdate =async (file)=>{
        setLoading(true);
        try{
            const formData = new FormData();
            formData.append("illustration", file);
            formData.append('formation',JSON.stringify(newFormation));
            const {data} = await axios.put(config.api+"formations",formData,
                {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken,
                        'content-type': 'multipart/form-data',

                    }
                }
            );
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "Sauvegardé"
            });
            const newList = formationList.map(f=>{
                if(f.id===newFormation.id){
                    return data;
                }else{
                    return f;
                }
            });
            setFormationList(newList);
            setNewFormation(defaultFormation);
            setLoading(false);

        }catch (e) {
            setLoading(false);

            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
        }

    };

    const displayScreen = ()=>{
        switch (value) {
            case 0 :
                return <FormationsWisardContainer formationType={formationType}
                                                  conferenceRooms={conferenceRooms}
                                                  speakerList={speakerList}
                                                  onFinished={create}
                                                  newFormation={newFormation}
                                                  setNewFormation={setNewFormation}
                />;
            case 1 :
                return <FormationsUpdateContainer formationType={formationType}
                                                  conferenceRooms={conferenceRooms}
                                                  speakerList={speakerList}
                                                  formationList={formationList}
                                                  toUpdate={newFormation}
                                                  setToUpdate={setNewFormation}
                                                  pushUpdate={pushUpdate}

                />;
            default :
                return <FormationsDeleteContainer formationList={formationList}
                                                  setFormationList={setFormationList}
                                                  setSnackBarDisplay={setSnackBarDisplay}

                />;
        }
    };

    if(loading){
        return (
            <CircularProgress  />
        );
    }

    return (
        <Container
            style={{
                addingTop:"20px"
            }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>

            <Grid container
                  direction="row"
                  justify="flex-start"
            >

                <Grid item xs={9}>
                    {displayScreen()}
                </Grid>
                <Grid item xs={3}>
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <Tab label="Ajouter" icon={<AddIcon />} />
                        <Tab label="Update" icon={<UpdateIcon />} />
                        <Tab label="Supprimer" icon={<DeleteIcon />} />
                    </Tabs>
                </Grid>
            </Grid>



        </Container>
    )
};

export default FormationsContainer;
