import React, {useContext, useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {amber} from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {UserContext} from "../../../context/user-context";
import axios from "axios";
import config from "../../../config/api";
import {withRouter} from "react-router-dom";


const useStyles = makeStyles(theme=>({
    root:{
        paddingTop : theme.spacing(2),
        paddingLeft : theme.spacing(2),
    },
    topPadded:{
        paddingTop : theme.spacing(2),
    },
    pending: {
        backgroundColor: amber[500],
        minWidth :250
    },
    ok: {
        backgroundColor: theme.palette.tertiary.main,
        minWidth :250
    },
    bordered : {
        borderColor : "primary.main",

    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop : theme.spacing(2),
        paddingBottom : theme.spacing(2),
        textAlign: "center"
    },

}));

//formationsFlattedList est la liste des formations dispo flatted
const PastReservationsTab = withRouter((props)=>{
    const classes = useStyles();
    const {userReservation, user}=useContext(UserContext);
    const [notPanierReservation, setNotPanierReservation] = useState([]);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [userDataDump, setUserDataDump]= useState({});

    useEffect(()=>{
        (async()=>{
            const {data} = await axios.get(config.api+"users", {
                headers : {
                    "authorization": "Bearer "+user.accessToken
                }
            });
            setUserDataDump(data);
        })();
    },[]);

    useEffect(()=>{
        //preparation de la liste de réservation
        const formated = userReservation.reservationsList
            .filter(res => {
                return res.status!=="panier";
            })
            .sort((res1, res2 )=>{
                if(res1.Formation.name<res2.Formation.name){
                    return -1;
                }
                if(res1.Formation.name>res2.Formation.name){
                    return 1;
                }
                return 0

            });
        setNotPanierReservation(formated)
    }, [userReservation,props.formationsFlattedList]);

    const displayChip = (status, num)=>{
        if(status==="pending"){
            return(
                <>
                    <Chip label="En attente de paiement" className={classes.pending} />
                    <br/>
                    <Typography  variant="p" color="primary" >
                        numéro de suivi : {num}
                    </Typography>

                </>
            )
        }
        if(status==="paid"){
            return(
                <>
                    <Chip label="payé" className={classes.ok} />
                    <br/>
                    <Typography  variant="p" color="primary" >
                        numéro de suivi : {num}
                    </Typography>
                </>
            )
        }
    };

    if(!notPanierReservation[0]){
        return(
            <Container className={classes.instructions}>
                Aucune formation réservée
            </Container>
        )
    }

    return (
        <Container className={classes.root}  >
            <Paper >
                    {
                        notPanierReservation.map((reservation, index) => {
                            return(
                                <Grid container className={classes.root}>
                                    <Grid item xs={12} md={6} className={classes.topPadded}>

                                        <Typography  variant="h6" color="primary" component="span" >
                                            {reservation.Formation.name}
                                        </Typography>
                                        {"  ("
                                        +new Date(reservation.Formation.Calendars[0].startDate).toLocaleDateString("fr-FR", options)+")"}
                                    </Grid>
                                    <Grid item xs={12} md={2} className={classes.topPadded}>
                                            <Button variant={"contained"}  color="primary" disabled={reservation.status==="paid"}
                                                onClick={()=>{
                                                    props.history.push("/fail/"+reservation.transactionId)
                                                }}
                                            >
                                                Annuler
                                            </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.topPadded}>
                                        {displayChip(reservation.status, reservation.transactionId)}
                                    </Grid>


                                    <Grid item xs={12}>

                                        <Typography  variant="subtitle1" color="textSecondary" component="p" >
                                            liste des inscrits
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {userDataDump.firstName+" "+userDataDump.lastName}

                                    </Grid>
                                    <Grid item xs={12}>

                                        {reservation.Assistantes.map(ass =>{
                                            return (
                                                <p>{ass.firstName+" "+ass.lastName}</p>

                                            )
                                        })}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    </Grid>

                            )

                        })
                    }
            </Paper>
        </Container>
    );
});


export default  PastReservationsTab;
