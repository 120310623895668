import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import Container from "@material-ui/core/Container";
import axios from "axios";
import config from "../../config/api";
import {UserContext} from "../../context/user-context";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: 20,
        marginTop: 10
    },
    progress: {
        margin: theme.spacing(2),
        color : theme.palette.primary.main
    },
    checked: {
        color: theme.palette.tertiary.main,
        margin: theme.spacing(2),
    },
    cancel: {
        margin: theme.spacing(2),
        color: theme.palette.secondary.main
    },
}));
const SuccessSaferPay = ({match}) =>{

    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [success, setSuccess] =useState(false);
    const [message, setMessage] =useState("");
    const {user, forceRefreshPanier}=useContext(UserContext);

    useEffect(()=>{
        (async()=>{
            //appelle cloud
            if(!user.accessToken){
                return;
            }
            try{
            const {data}= await axios.delete(config.api+"reservations?transactionId="+match.params.tid,
                {
                    headers : {
                        "authorization" : "Bearer "+user.accessToken
                    }
                });

             setSuccess(true);
             await forceRefreshPanier();

            }catch (e) {
                setSuccess(false);
                setMessage(e.response.data.message)
            }
            setLoading(false)
        })();
    }, [match.params.tid, user]);


    if(loading){
        return (
            <Container
                maxWidth="md">
                <Grid container
                      direction="column">
                    <Grid item>
                        <CircularProgress className={classes.progress} fontSize="large"/>
                    </Grid>
                    <Grid item>
                        <Typography align="center" variant="p">
                            Annulation de la transaction. <br/> Cette opération peut prendre quelques secondes
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Container
            maxWidth="md">
            {success && (
                <Grid container
                      direction="column">
                    <Grid item>
                        <CheckIcon className={classes.checked} fontSize="large"/>
                    </Grid>

                    <Grid item>
                        <Typography align="center" variant="p">
                            Transaction annulée
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {!success && (
                <Grid container
                      direction="column">
                    <Grid item>
                        <CancelIcon className={classes.cancel} color="error" fontSize="large"/>
                    </Grid>

                    <Grid item>
                        <Typography align="center" variant="p">
                           Un problème est survenu :
                        </Typography>
                        <Typography align="center" variant="p" color="secondary">
                            {message}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Container>

    )

};
export default SuccessSaferPay;
