import React, {useContext, useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import {performPrice, performReduction} from "../../../utils/PerformPrice";
import {UserContext} from "../../../context/user-context";



const useStyles = makeStyles(theme=>({

    title:{
        margin: theme.spacing(1),
        minWidth: 650,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
}));

//formatedReservations est la liste flat des réservation du client courrant accompagnées des infos de la formations concernées
const SummingRow = ({ onlyPanierFormatedReservation, userData})=>{
    const classes = useStyles();
    const [currentClientSubTotal, setCurrentClientSubTotal] = useState(0);
    const [assistanteSubTotal, setAssistanteSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [reduction, setReduction]=useState(undefined);
    const {user, userReservation, isDentist} = useContext(UserContext);

    useEffect(()=>{
        //calcul la somme des prix des formations dans le panier
        const currentClientsubTot =
            onlyPanierFormatedReservation
                .reduce((accu, current)=>{

                    return  accu + performPrice(
                        current.Formation,
                        userData.inami,
                        !isDentist,
                        false).price;

                },0);

        const assistanteSubTot =
            onlyPanierFormatedReservation
                .reduce((accu, current)=>{
                    const currentClientPrice =  performPrice(
                        current.Formation,
                        undefined,
                        false,
                        true).price;
                    return  accu + currentClientPrice*current.Assistantes.length;

                },0);

        setCurrentClientSubTotal(currentClientsubTot);
        setAssistanteSubTotal(assistanteSubTot);

        //Attention à bien passer tout le panier utilisateur (historique et panier courrant) pour calculer la réduction
        const red = performReduction(userReservation.reservationsList);
        setReduction(red);
        const tot = currentClientsubTot + assistanteSubTot - red.reduction;
        setTotal(tot)
    }, [onlyPanierFormatedReservation,user,userReservation.reservationsList])


    const performeResTotalPrice = (res)=>{
        const main = performPrice(
            res.Formation,
            userData.inami,
            !isDentist,
            false).price;

        const assistPrice= performPrice(
            res.Formation,
            undefined,
            false,
            true).price * res.Assistantes.length;

        return main +assistPrice;

    };



    const displaySubTotalRow = (res, index)=>{
        return(
            <TableRow key={index}>
                <TableCell component="th" scope="row">
                    {res.Formation.name}
                </TableCell>
                <TableCell align="left">
                    1  {res.Assistantes.length>0 && "+ "+res.Assistantes.length+ " assistante(s)"}
                </TableCell>
                <TableCell align="left">
                    {performeResTotalPrice(res)} €
                </TableCell>
            </TableRow>
        );
    };

    return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Formation</TableCell>
                            <TableCell align="left">Nb d'inscription</TableCell>
                            <TableCell align="left">Prix</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {   //filtre les réservations : seule celle qui nous concernent nous intéressent
                            onlyPanierFormatedReservation.map((reservation, index) => {
                                return displaySubTotalRow(reservation, index);
                            })
                        }

                    <TableRow>
                        <TableCell  align="right" colSpan={2}>
                            <Typography  variant="body2" color="textSecondary" component="span" >
                            Votre sous total
                            </Typography>
                        </TableCell>

                        <TableCell align="left">
                            <Typography variant="body2" color="textSecondary" component="span" >
                                 {currentClientSubTotal} €
                            </Typography>
                        </TableCell>

                    </TableRow>
                    {(reduction && reduction.reduction !==0) && (
                        <TableRow>
                            <TableCell  align="right" colSpan={2}>
                                <Typography  variant="body2" color="textSecondary" component="span" >
                                Réduction fidélité pour votre inscritpion à {reduction.fcCount} FC
                                </Typography>
                        </TableCell>
                            <TableCell  align="left">
                            <Typography variant="body2" color="textSecondary" component="span" >
                                 - {reduction.reduction} €
                            </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {reduction && reduction.fcRedGot !==0 && (
                        <TableRow>
                            <TableCell align="right" colSpan={3}>
                                <Typography  variant="body2" color="textSecondary" component="span" >
                                Vous avez déjà obtenu la réduction du palier {reduction.fcRedGot} FC lors d'une précédente réservation
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {assistanteSubTotal!==0 && (
                    <TableRow>
                        <TableCell  align="right" colSpan={2}>
                            <Typography  variant="body2" color="textSecondary" component="span" >
                            Sous total de vos assistante(s)
                            </Typography>
                        </TableCell>

                        <TableCell  align="left">

                        <Typography variant="body2" color="textSecondary" component="span" >
                            {assistanteSubTotal} €
                        </Typography>
                        </TableCell>
                    </TableRow>
                    )}
                    <TableRow>
                        <TableCell  align="right" colSpan={2}>
                            <Typography variant="h6" color="primary" component="span" >
                                Total
                            </Typography>
                        </TableCell>
                        <TableCell  align="left">

                        <Typography variant="h6" color="primary" component="span" >
                        {total} €
                        </Typography>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </Paper>
    );
};


export default  SummingRow;
