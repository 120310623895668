import React, {useContext, useState} from "react";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import {makeStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import BookmarkIcon from "@material-ui/core/SvgIcon/SvgIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from "../Shared/MySnackBarContentWrapper";
import DialogTitle from "@material-ui/core/DialogTitle";
import {UserContext} from "../../context/user-context";
import {withRouter} from "react-router-dom";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme=>({

    appBar: {
        position: 'relative',
    },
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height : "100%",

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
    duotextFiedl:{
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }

}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConnexionPopUp = withRouter(props => {
    const [snackBarDisplay, setSnackBarDisplay] = useState({
        open: false,
        variant: "error",
        message: ""
    });
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [isConnecting, setIsConnecting] = useState(true);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const { logIn, signIn, user} = useContext(UserContext);

    const [values, setValues] = React.useState({
        mail: '',
        password: '',
        verifyPassword :'',
        inami:'',
        address_street:'',
        address_number:'',
        address_postalCode:'',
        address_city:'',
        address_country: 'Belgique',
        lastName:'',
        firstName:''
    });

    const verifyPassword = () =>{
        if(values.password!==values.verifyPassword){
            setValues({ ...values, password: "", verifyPassword: "" });
            return false;
        }
        return true;
    };



    //modifie les state contenant les infos utilisateurs
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleCloseInfos = () => {
        props.closeCallBack();
        setOpen(false);
    };

    const handleConnection = async ()=>{
        setIsLoadingButton(true);
        try{
            await logIn(values.mail, values.password);
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "connecté"
            });
            setTimeout(handleCloseInfos, 1000);
        }catch (e) {
            console.log(e)
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });

        }
        setIsLoadingButton(false);


    };

    const handelCreation =  async ()=>{
        setIsLoadingButton(true);
        if(!verifyPassword()){
            setIsLoadingButton(false);
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: "les mots de passe ne correspondent pas"
            });
            return;
        }
        try{
            await signIn(values);
            setSnackBarDisplay({
                open: true,
                variant: "success",
                message: "Inscription effectuée"
            });
            setTimeout(handleCloseInfos, 1000);
        }
        catch (e) {
            setSnackBarDisplay({
                open: true,
                variant: "error",
                message: e.response.data.message
            });
        }
        setIsLoadingButton(false);
    };

    return(
        <Dialog fullScreen open={open} onClose={handleCloseInfos} TransitionComponent={Transition}>
            <DialogTitle>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseInfos} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {isConnecting? "Connexion" : "Inscription"}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent className={classes.container}>

                {!isConnecting && (

                    <Typography variant="body2" color="textSecondary" style={{marginTop: "20px"}}>
                        Tous les champs seront obligatoires et strictement nécessaires pour la gestion de vos réservations.
                        Vous pouvez les modifier à tout moment dans votre profil
                    </Typography>
                )}
                <form>
                    <Container>

                    <Grid container
                          justify="flex-start"
                          direction="column">
                        <TextField
                            required
                            onChange={handleChange("mail")}
                            value={values.mail}
                            id="filled-email-input"
                            label="Email"
                            className={classes.textField}
                            type="email"
                            name="email"
                            autoComplete="email"
                            margin="normal"
                            variant="filled"
                        />
                        <TextField
                            required
                            id="filled-email-input"
                            onChange={handleChange("password")}
                            value={values.password}
                            label="Mot de passe"
                            className={classes.textField}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            variant="filled"
                        />


                        {/*user is trying to connect========================================================================================================*/}

                        {isConnecting && (
                            <>
                                <Grid container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                >

                                    <Button   color="primary" onClick={()=>setIsConnecting(false)}>
                                        Créer un compte
                                        <BookmarkIcon className={classes.rightIcon} />
                                    </Button>

                                    <Button  variant={"contained"}  color="primary" onClick={handleConnection} disabled={isLoadingButton || user.accessToken }>
                                        {!user.accessToken && "Connexion"}
                                        {user.accessToken && "Connecté"}
                                        {isLoadingButton && (
                                            <CircularProgress className={classes.rightIcon} />
                                        )}
                                        {!isLoadingButton && (
                                            <BookmarkIcon className={classes.rightIcon} />
                                        )}
                                    </Button>



                                </Grid>
                                <Button   color="primary" onClick={()=>{
                                    props.history.push("/passwordInit/1");
                                    handleCloseInfos();
                                }}>
                                    mot de passe oublié?
                                </Button>
                            </>
                        )}


                        {/*user is trying to create an account=============================================================================================*/}

                        {!isConnecting && (
                            <>
                                <Grid container
                                      justify="flex-start"
                                      direction="column"
                                >
                                    <TextField
                                        required
                                        id="filled-verifyPassword-input"
                                        onChange={handleChange("verifyPassword")}
                                        value={values.verifyPassword}
                                        label="Mot de passe : Vérification"
                                        className={classes.textField}
                                        type="password"
                                        autoComplete=""
                                        margin="normal"
                                        variant="filled"
                                    />

                                    <Grid container
                                          direction="row"
                                          justify="space-between">

                                        <TextField
                                            id="filled-name-input"
                                            onChange={handleChange("lastName")}
                                            className={classes.duotextFiedl}
                                            value={values.lastName}
                                            label="Nom"
                                            type="text"
                                            autoComplete=""
                                            margin="normal"
                                            variant="filled"
                                        />
                                        <TextField
                                            id="filled-surname-input"
                                            onChange={handleChange("firstName")}
                                            label="Prénom"
                                            className={classes.duotextFiedl}
                                            value={values.firstName}
                                            type="text"
                                            autoComplete=""
                                            margin="normal"
                                            variant="filled"
                                        />
                                    </Grid>
                                </Grid>



                                <Grid container
                                      direction="column"
                                      justify="space-between"
                                >
                                    <TextField
                                        id="filled-address1-input"
                                        onChange={handleChange("address_street")}
                                        value={values.address_street}
                                        label="Adresse"
                                        className={classes.textField}
                                        type="text"
                                        autoComplete=""
                                        margin="normal"
                                        variant="filled"
                                    />
                                    <TextField
                                        id="filled-address1-input"
                                        onChange={handleChange("address_number")}
                                        value={values.address_number}
                                        label="Numero et boite"
                                        className={classes.textField}
                                        type="text"
                                        autoComplete=""
                                        margin="normal"
                                        variant="filled"
                                    />


                                    <Grid container
                                          direction="row"
                                          justify="space-between">

                                        <TextField
                                            id="filled-cp-input"
                                            onChange={handleChange("address_postalCode")}
                                            label="Code postal"
                                            type="text"
                                            className={classes.duotextFiedl}
                                            value={values.address_postalCode}
                                            autoComplete=""
                                            margin="normal"
                                            variant="filled"
                                        />
                                        <TextField
                                            id="filled-city-input"
                                            onChange={handleChange("address_city")}
                                            label="Ville"
                                            type="text"
                                            className={classes.duotextFiedl}
                                            value={values.address_city}
                                            autoComplete=""
                                            margin="normal"
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid container
                                          direction="row"
                                          justify="space-between">

                                        <TextField
                                            onChange={handleChange("address_country")}
                                            label="Pays"
                                            type="text"
                                            className={classes.textField}
                                            value={values.address_country}
                                            autoComplete=""
                                            margin="normal"
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid container
                                          direction="row"
                                          justify="space-between">

                                        <TextField
                                            onChange={handleChange("inami")}
                                            label="N° Inami"
                                            type="text"
                                            className={classes.textField}
                                            value={values.inami}
                                            autoComplete=""
                                            margin="normal"
                                            variant="filled"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container
                                      direction="row"
                                      justify="space-between"
                                >

                                    <Button   color="primary" onClick={()=>setIsConnecting(true)}>
                                        Déjà un compte ?
                                        <BookmarkIcon className={classes.rightIcon} />
                                    </Button>

                                    <Button  variant={"contained"}  color="primary" onClick={handelCreation} disabled={isLoadingButton || user.accessToken } >
                                        {!user.accessToken && "Création"}
                                        {user.accessToken && "Bienvenue !"}
                                        {isLoadingButton && (
                                            <CircularProgress className={classes.rightIcon} />
                                        )}
                                        {!isLoadingButton && (
                                            <BookmarkIcon className={classes.rightIcon} />
                                        )}
                                    </Button>

                                </Grid>

                            </>
                        )}
                    </Grid>
                    </Container>

                </form>
            </DialogContent>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>
        </Dialog>
    );
})

export default ConnexionPopUp;
