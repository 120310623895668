import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from "react-router-dom"

const DropDownMenu = withRouter((props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(link) {
        setAnchorEl(null);

        if(typeof link ==="string"){
            props.history.push("/"+link);
        }
        if(typeof  link ==="function"){
            link();
        }
    }

    return (
        <div>
            <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={handleClick}>
                {props.title}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}

            >
                {props.content &&(
                    props.content.map((m, index) => <MenuItem
                        key={index}
                        onClick={()=>handleClose(m.link)}>{m.displayName}</MenuItem>)
                )}
            </Menu>
        </div>
    );
});

export default DropDownMenu;
