import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import utilsFormat from "../../utils/FormatTextUtil";
const useStyles = makeStyles({
    card: {
        maxWidth: 345,
        height: "100%"
    },
    media: {
        height: 140,
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
    }
});

export default function Annonces({data}) {
    const classes = useStyles();
    const [isInfosDisplayed, setIsInfosDisplayed] = useState(false);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const switchInfos = () => {
        setIsInfosDisplayed( !isInfosDisplayed);
    };
    return (
        <Card className={classes.card}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {utilsFormat.AnnoncesTypeFormat(data.type)}
                    </Typography>

                    <Typography variant="body2" color="textSecondary" component="p">
                        {data.description}
                    </Typography>
                    <Typography  variant="body2" color="textSecondary" component="p" className={classes.divider}>
                        publié le : {new Date(data.publicationDate).toLocaleDateString("fr-FR", options)}
                    </Typography>
                    {isInfosDisplayed && (
                        <>
                            <Divider className={classes.divider}/>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.divider}>
                            {(data.mail)? "Mail: " + data.mail : ""}
                            <br/>
                            {(data.phone)? "Tel: "+ data.phone : ""}
                        </Typography>
                        </>
                    )}
                </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={switchInfos}>
                    {isInfosDisplayed ? "Cacher" : "Contact" }
                </Button>
            </CardActions>
        </Card>
    );
}
