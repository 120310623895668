import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {performPrice} from "../../../utils/PerformPrice";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {UserContext} from "../../../context/user-context";
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(1),
    },
}));

export default function SelfReservation({onlyPanierFormatedReservation, userData}) {
    const classes = useStyles();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const {isDentist, setIsDentist, user, userReservation, setUserReservation} = useContext(UserContext);


    const displayHoraire = (calendar)=>{
        const startDate = new Date(calendar.startDate);
        const stopDate = new Date(calendar.stopDate);
        return "de "+startDate.getHours()+"h à "+stopDate.getHours()+"h"

    };

    const handleClientTypeChange= (newType)=>{
        //on va set les réservations de type "panier" du context pour update les clienttype et forcer le refresh
        //le client type choisi ici est répercuté sur tout le panier courant.
        //donc, une assistante peut s'inscrire seule avec le compte d'un dentiste
        const filtred = userReservation.reservationsList
            .filter(res =>{
                return res.status==="panier"
            })
            .map(res =>{
                return {
                    ...res,
                    clientType: newType,
                    assistantes:[]
                }
            });

        const notFiltered = userReservation.reservationsList
            .filter(res =>{
                return res.status !=="panier";
            });
        setUserReservation({reservationsList: [...filtred, ...notFiltered]});
    };





    const displayPrice = (reservationFed) => {
        const pricing = performPrice(reservationFed.Formation, userData.inami,
            !isDentist, false);
        return (
            <>
                {pricing.price + " € "}
                <Typography variant="body2" color="textSecondary" component="p" >
                    {pricing.message}
                </Typography>
            </>
        )
    };

    const handleDelete = (reservationFed)=>{
        const toChange = userReservation.reservationsList.filter(ur =>{
            return ur.id!==reservationFed.id

        });
        setUserReservation({reservationsList : toChange});
    };
    return (
        <>
        <Grid container
              justify="flex-start"
              direction="row"
              className={classes.title}>

            <Typography variant="body2" color="textSecondary" component="div" className={classes.fab} >
                Je suis :
            <br/>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={isDentist}
                        onChange={()=>setIsDentist(!isDentist)}
                        value="checkedA"
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                    />
                }
                label={
                    <Typography variant="body2" color="textSecondary" component="p" >
                        Dentiste
                    </Typography>
                }
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!isDentist}
                        onChange={()=>setIsDentist(!isDentist)}
                        value="checkedA"
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                    />
                }
                label={
                    <Typography variant="body2" color="textSecondary" component="p" >
                        Etudiant(e)
                    </Typography>
                }
            />

            </Typography>
        </Grid>



        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Formation</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Horaire</TableCell>
                        <TableCell align="left">Prix</TableCell>
                        <TableCell align="left">Supprimer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {   //filtre les réservations : seule celle qui nous concernent nous intéressent
                        onlyPanierFormatedReservation.map((reservation, index) => {
                            return(
                                <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {reservation.Formation.name}
                                </TableCell>
                                <TableCell align="left">{new Date(reservation.Formation.Calendars[0].startDate).toLocaleDateString("fr-FR", options)}</TableCell>
                                <TableCell align="left">{displayHoraire(reservation.Formation.Calendars[0])}</TableCell>
                                <TableCell align="left">
                                    {displayPrice(reservation)}
                                </TableCell>
                                <TableCell align="left">
                                    <Fab aria-label="delete" className={classes.fab} onClick={()=>handleDelete(reservation)}>
                                        <DeleteIcon />
                                    </Fab>

                                </TableCell>
                            </TableRow>)

                        })
                    }
                </TableBody>
            </Table>
        </Paper>
            </>
    );
}
