import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../Shared/MySnackBarContentWrapper";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(theme=>({
    root: {
        marginTop:20
    },
    padding: {
        padding : "20px"
    },
    formControl: {
        paddingTop : "20px",
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


const JodView =  ({  loading,
                     postJod,
                     jodNumber,
                     setJodNumber,
                     snackBarDisplay,
                     setSnackBarDisplay
                 },{refPdf, refThumb} ) => {

    const classes = useStyles();

    if(loading){
        return (
            <CircularProgress className={classes.progress} />
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackBarDisplay.open}
                autoHideDuration={6000}
                onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
            >
                <MySnackbarContentWrapper
                    onClose={()=>{setSnackBarDisplay({...snackBarDisplay, open:false})}}
                    variant={snackBarDisplay.variant}
                    message={snackBarDisplay.message}
                />
            </Snackbar>

            <Container
                maxWidth="md"
                className={classes.padding}>
                <Paper className={classes.padding} >


                    <Typography variant="body1" color="textSecondary" component="p" >
                        Pour des raisons techniques, je suis incapable de générer la miniature depuis le pdf
                        dans le V8 engine coté server. (Il y a des peers dependencies liées à l'OS, et je n'ai pas de
                        controle sur l'OS avec notre hébergement).
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="textSecondary" component="p" >
                        En d'autres termes : il faut me donner une miniature (format jpg ou png)
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="textSecondary" component="p" >
                        Essayez de me fournir une miniature de taille 70x99 px
                        (comme ca, ca fout pas le brol sur la page des JOD)
                    </Typography>

                </Paper>
                <form >
                    <FormControl className={classes.formControl}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <label>
                                    <Typography variant="body1" color="textSecondary" component="p" >
                                        Numéro du JOD
                                    </Typography>
                                </label>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                            >
                                <input
                                    style={{
                                        height: "20px"
                                    }}
                                    type="number"
                                    value={jodNumber}
                                    id="number"
                                    name="number"

                                    onChange={(e)=>setJodNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                className={classes.padding}
                            >
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <label>
                                        <Typography variant="body1" color="textSecondary" component="p" >
                                            PDF
                                        </Typography>
                                    </label>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <input
                                        type="file"
                                        id="pdf"
                                        name="pdf"
                                        accept="application/pdf"
                                        ref={refPdf}

                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                className={classes.padding}
                            >
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <label>
                                        <Typography variant="body1" color="textSecondary" component="p" >
                                            Miniature
                                        </Typography>
                                    </label>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <input
                                        type="file"
                                        id="thumbnail"
                                        name="thumbnail"
                                        accept="image/png, image/jpeg"
                                        ref={refThumb}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            className={classes.padding}
                        >
                            <Button  variant={"contained"}  color="primary" onClick={postJod} >
                                Submit
                            </Button>
                        </Grid>

                    </FormControl>
                </form>
            </Container>

        </>
    );
};


export default React.forwardRef(JodView);
