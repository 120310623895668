import React, {useContext, useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import PersonalDataTab from "./PersonnalDataTab/PersonnalDataTab";
import CircularProgress from "@material-ui/core/CircularProgress";
import BookingTab from "./BookingTab/BookingTab";
import Badge from "@material-ui/core/Badge/Badge";
import PastReservationsTab from "./HistoryTab/PastReservationsTab";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {UserContext} from "../../context/user-context";
import axios from "axios";
import config from "../../config/api"
const useStyles = makeStyles(theme=>({
    root: {
        marginTop:20
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
}));



const UserProfil = (props)=>{
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { user, userReservation} =useContext(UserContext);
    const [countPanier, setCountPanier] = useState(0);
    const [formationsFlattedList, setFormationsFLattedList]=useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        const count = userReservation.reservationsList.filter(res=> {
            return res.status==="panier";
        }).length;

        setCountPanier(count);
    },[userReservation]);

    useEffect(()=>{
        (async ()=>{

            const {data} = await axios.get(config.api+"formations");
            setFormationsFLattedList(data.formations);
            setIsLoading(false)
        })();

    },[]);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    if(!user.accessToken){
        return(
            <div>
                Vous devez être connecté pour accéder à cette page
            </div>
        )
    }

    if(isLoading){
        return (
            <CircularProgress className={classes.progress} />
        );
    }

    return(
        <Container>
            <Paper square className={classes.root}>
                <Grid container
                justify="center">
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        scrollButtons="on"
                        variant="scrollable"

                    >
                        <Tab label={
                            <Badge className={classes.padding} badgeContent={countPanier} color="secondary">
                                Votre panier
                            </Badge>
                        }/>

                        <Tab label="Vos Réservations" />
                        <Tab label="Données personnelles" />

                    </Tabs>
                </Grid>
            </Paper>


            {value === 0 &&<BookingTab formationsFlattedList={formationsFlattedList}/>}
            {value === 1 && <PastReservationsTab formationsFlattedList={formationsFlattedList}/>}
            {value === 2 && <PersonalDataTab/>}
        </Container>
    );
}


export default  UserProfil;
