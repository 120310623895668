import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import React from "react";
import {makeStyles} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import utils from "../../../../utils/FormatTextUtil"
import Divider from "@material-ui/core/Divider";
const useStyles = makeStyles(theme=>({

    appBar: {
        position: 'relative',
    },
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height : "100%"
    },
    dense: {
        marginTop: 16,
        marginBottom: 16,
    },
    menu: {
        width: 200,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VirementValidationModalSummary = ({reservations, open,close})=>{

    const classes = useStyles();

    return(
        <Dialog maxWidth={"sm"} fullWidth open={open} TransitionComponent={Transition} onClose={close}>
            <DialogContent className={classes.container}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Détails de la commande : {reservations[0].transactionId}
                        </Typography>


                    </Toolbar>
                </AppBar>

                <Typography variant="h6" className={classes.title}>
                   Formations réservées
                </Typography>
                <Divider className={classes.dense}/>

                <Grid container
                          justify="flex-start"
                          direction="column">
                        {
                            reservations.map(res =>{
                                return (
                                    <>
                                    <Grid item>
                                        <Typography variant="p" color={"textSecondary"} className={classes.title}>
                                            {res.Formation.name}
                                        </Typography>

                                    </Grid>
                                        <br/>
                                        <Grid item>
                                            <Typography variant="p"  color={"textSecondary"} className={classes.title}>
                                                réservation pour un {utils.ClientTypeFormat(res.isStudent)} et {res.Assistantes.length} assistante(s)
                                            </Typography>
                                        </Grid>
                                        <Divider className={classes.dense}/>
                                    </>
                                )}
                            )
                        }
                    </Grid>
                <Divider className={classes.dense}/>

                <Typography variant="h6" className={classes.title}>
                    Facturation
                </Typography>

                <Grid container
                      justify="flex-start"
                      direction="column">

                <Typography variant="p"  color={"textSecondary"} className={classes.title}>
                    {reservations[0].billing_Title} <br/>
                    {reservations[0].billing_address_street} {reservations[0].billing_address_number} <br/>
                    {reservations[0].billing_address_postalCode}  {reservations[0].billing_address_city} <br/>
                    {reservations[0].billing_address_country}
                </Typography>
                    <Typography variant="p" color="secondary" className={classes.title}>
                        Prix total de la commande : {reservations[0].transaction_price_asked} €
                    </Typography>
                    <Typography variant="p" color="textSecondary" className={classes.title}>
                        payement validé le : {(reservations[0].capture_date? new Date(reservations[0].capture_date).toLocaleString() : "en attente")}
                    </Typography>
                    <Typography variant="p" color="textSecondary" className={classes.title}>
                        payement validé par : {reservations[0].validator}
                    </Typography>
                </Grid>
                <Divider className={classes.dense}/>

                <Typography variant="h6" className={classes.title}>
                    Contact
                </Typography>
                <Grid container
                      justify="flex-start"
                      direction="column">
                <Typography variant="p"  color={"textSecondary"}>
                    {reservations[0].User.firstName} {reservations[0].User.lastName} : {reservations[0].User.mail}
                </Typography>
                    <Typography variant="p"  color={"textSecondary"}>
                        Inami : {reservations[0].User.inami}
                    </Typography>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default VirementValidationModalSummary
