import React from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import codLogo from "../../assets/cod-logo.svg";
import Divider from "@material-ui/core/Divider";
import facebook from "../../assets/images/facebook.png"
import Typography from "@material-ui/core/Typography";
import utilsFormat from "../../utils/FormatTextUtil";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";



const Footer = (props)=>{



    const useStyles = makeStyles(theme=>({
        root:{
            width : "100%",
            backgroundColor : "#2e282a",
            minHeight : "200px",
            marginTop : props.marginTop||"0px",
            position: props.position,
            bottom:"0px"

        },
        image : {
            maxWidth: "80%",
            paddingLeft :"10%"
        },
        compense : {
            width : "100%",
            backgroundColor : "#2e282a",
        }

    }));
    const style = useStyles();
    const sendMail = () =>{
        const link = "mailto:info@cod.eu.com";

        window.location.href = link;
    }
    return (
        <>
            <Grid container
                  className={style.root}
                  direction="row"
                  alignItems="center"
            >
                <Grid item xs={4} >

                    <img src={codLogo} alt={"logo"} className={style.image}/>

                </Grid>

                <Grid item xs={4} >
                    <Grid container
                          direction="column"
                          justify="center"
                          alignContent="center"

                    >
                        <Grid item>

                            <Button aria-controls="simple-menu"  aria-haspopup="true"  color="secondary"  onClick={()=>window.location.href="https://www.facebook.com/Coll%C3%A8ge-dOmnipratique-Dentaire-COD-216821375894579/"}>
                                <img src={facebook}  style={{paddingRight: "10px", maxWidth:"30px"}}/>

                                Suivez-nous!

                            </Button>
                        </Grid>

                        <Grid item>

                            <Button aria-controls="simple-menu"  aria-haspopup="true"  color="secondary"  onClick={()=>sendMail()}>
                                contactez-nous

                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} >
                    <Grid container
                          direction="column"
                          justify="space-between"
                    >
                        <Button aria-controls="simple-menu"  aria-haspopup="true"  color="secondary" onClick={()=>props.history.push("/conditions")}>
                            Conditions générales
                        </Button>
                        <Button aria-controls="simple-menu" color="secondary"  aria-haspopup="true" onClick={()=>props.history.push("/protection")}>
                            Politique de protection des données
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <div className={style.compense}/>
        </>
    )



};

export default Footer;
