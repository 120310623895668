import React from 'react';
import PropTypes from 'prop-types';
import  {Parallax}  from 'react-parallax';
import Grid from "@material-ui/core/Grid";

const Section = ({ headerImage, headerTitle, parallaxStrength, id, children }) => (

    <Grid container>
        <Grid item
              xs={12}
        >
            <Parallax
                bgImage={
                    headerImage
                }
                strength={parallaxStrength}
            >
                <div style={{
                    height: '50vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                id={id}>
                    <h1 style={{
                        color: 'white',
                        fontSize: 46,
                        fontWeight: 'bold',
                        textAlign : "center"
                    }}
                    >
                        {headerTitle}
                    </h1>
                </div>
            </Parallax>
            <Grid item
                  xs={12}
            >
                {children}

            </Grid>
        </Grid>
    </Grid>

);

Section.propTypes = {
    headerImage: PropTypes.string.isRequired,
    headerTitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    parallaxStrength: PropTypes.node.isRequired,
};

export default Section;
