import React from "react";
import GlobalWrapper from "./views/GlobalWrapper";
import Footer from "../Footer/Footer";

function Landing(props) {

    return(
        <>
            <GlobalWrapper />
            <Footer position="relative" {...props}/>
        </>
    );
}

export default Landing;
