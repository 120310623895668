import React from 'react';

import Grid from "@material-ui/core/Grid";

import partenairesArray from "../../../../../assets/images/partenaires"



const PartenaireSection = (props) => {

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            component={"div"}
            style={{
                paddingTop : "100px",
                paddingBottom : "100px",
                paddingLeft : "10%",
                backgroundColor:"#3a405a",
            }}
        >
            {partenairesArray.map((p, index) => {
                return (
                    <Grid item
                          key={index}
                          xs={12}
                          sm={6}
                            md={3}
                          style={{
                              paddingTop : "10px"
                          }}
                       >
                        <img src={p} alt="p1"
                             style={{
                                 maxWidth: "200px",
                                 maxHeight: "100px"
                             }}/>

                    </Grid>
                );
            })}



        </Grid>

    );

};


export default PartenaireSection;
