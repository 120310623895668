import React from "react";
import Title from "../Shared/Title";
import {Container, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Footer from "../Footer/Footer";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        textAlign: "justify",
        textJustify: "inter-word",

    },
    container : {
        minHeight : "100vh"
    }
}));
function Agrement(props) {
    const classes = useStyles();

    return(
        <>
            <Title title={"Agrément"}/>
            <Container className={classes.container}>

            <Paper className={classes.root}>
                <Typography component="p" >
                    Vous devez, pour garder votre droit d’exercer en qualité de praticien de l’art dentaire en Belgique,
                    répondre aux exigences de la législation sur le maintien des Titres Professionnels Particuliers qui
                    est maintenant contrôlée par les Communautés.
                </Typography>
                <br/>
                <Typography component="p">
                    En plus du respect de toutes les législations qui contrôlent l’exercice de notre profession (AFCN,
                    contrôle de vos installations, publicité, déchêts,…) vous devez pour cela suivre 60 heures de formation
                    continue (accréditées en Belgique ou à l’étranger) réparties sur 6 ans avec un minimum de 20 heures tous
                    les deux ans.
                </Typography>
                <br/>

                <Typography component="p">
                    Ces années commencent le 1er juillet. Et vous devez conserver la preuve de ces formations. Au COD, le
                    diplôme qui vous est remis à chaque journée en atteste. S’ajoute à cela l’obligation de tenir à jours
                    des dossiers de vos patients et d’être inscrit dans un service de garde agréé.
                </Typography>



            </Paper>
            </Container>
            <Footer position="relative" marginTop="30px" {...props}/>

        </>
    );
}

export default Agrement;
