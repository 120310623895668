import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from "../Shared/Title";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Footer from "../Footer/Footer";
import axios from"axios";
import config from  "../../config/api"
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        minHeight:"100vh"
    },
    table: {
        minWidth: 650,
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
}));



export default function Accreditations(props) {
    const classes = useStyles();
    const [rows, setRows]=useState([]);
    const [isLoading, setIsLoading]=useState(true);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(()=>{
        (async () =>{
            const {data} = await axios.get(config.api+"accreditations");

            setRows(data);
            setIsLoading(false);
        })();
    },[]);

    if(isLoading){
        return (
            <CircularProgress className={classes.progress} />
        );
    }

    return (
        <>
            <Title title={"Accréditation"}/>
            <Container>
                <Button  variant={"contained"} color="primary" onClick={()=>window.open(config.server+"assets/accred.JPG", '_blank')}>
                    Accréditation 2019
                    <InfoIcon className={classes.rightIcon} />
                </Button>

                <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="left">Titre</TableCell>
                            <TableCell align="left">UA</TableCell>
                            <TableCell align="left">Dom</TableCell>
                            <TableCell align="left">Num</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {new Date(row.Formation.Calendars[0].startDate).toLocaleDateString("fr-FR", options)}
                                </TableCell>
                                <TableCell align="left">{row.Formation.name}</TableCell>
                                <TableCell align="left">{row.unit}</TableCell>
                                <TableCell align="left">{row.domaine}</TableCell>
                                <TableCell align="left">{row.numero}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            </Container>
            <Footer position="relative" marginTop="30px" {...props}/>

        </>
    );
}
