import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {performPrice} from "../../../utils/PerformPrice";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import MakeListUniqueKey from "../../../utils/MakeListUniqueKey";
import NamePrompter from "./NamePrompter";
import DeleteIcon from '@material-ui/icons/Delete';
import {UserContext} from "../../../context/user-context";
import axios from "axios";
import config from "../../../config/api"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(1),
    },
    appBar: {
        position: 'relative',
    },
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height : "100%"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
}));



export default function AssistanteAddReservation({formatedReservations}) {
    const classes = useStyles();

    const {userReservation, setUserReservation, user} = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [currentReservationAdding, setCurrentReservationAdding] = useState(undefined);
    const [assistantesList, setAssistantesList]= useState([]);

useEffect(()=>{
    console.log(formatedReservations)
}, [formatedReservations])

    useEffect(()=>{
        (async ()=>{
            const {data} = await axios.get(config.api+"assistantes",{
                headers : {
                    "authorization" : "Bearer "+user.accessToken
                }
            })
            setAssistantesList(data)
        })();
    },[]);


    const displayPrice = (reservation) => {
        const pricing = performPrice(reservation.Formation, undefined,
            false, true);
        return (
            <>
                {pricing.price + " € "}
                <Typography variant="body2" color="textSecondary" component="p" >
                    {pricing.message}
                </Typography>
            </>
        )
    };

    const handleDelete = (reservationId, assistanteId) =>{

        //trouve la réservation à modifier sur base de son id de réservation

        const toChange = userReservation.reservationsList.find(ur =>{
            return ur.id ===reservationId;
        });
        console.log(toChange)

        //conserve les réservations à ne pas changer
        const toKepp = userReservation.reservationsList.filter(ur =>{
            return ur.id !==reservationId;
        });

        //filtre uniquement les réservations assistantes liée à concerver
        const assistanteAddedToKeep = toChange.Assistantes.filter((assistante)=>{
            return assistante.id !== assistanteId
        });
        //assigne les réservations assistantes liée à concerver
        toChange.Assistantes = assistanteAddedToKeep;

        //réaffecte la réservation modifiée
        toKepp.push(toChange);

        //setState
        setUserReservation({reservationsList : toKepp});
    };


    const handleAddAssistante = (reservation) =>{
        setCurrentReservationAdding(reservation);
        setOpen(true);
    };

    const handleClosePrompter = (infos, newAssistante)=>{
        if(!infos){
            setOpen(false);
            return;
        }
        const toAdd=currentReservationAdding;
        toAdd.Assistantes.push({
            firstName: infos.firstName,
            lastName: infos.lastName,
            id : infos.id
        });
        if(newAssistante){
            setAssistantesList([...assistantesList, infos])
        }

        //update context with this reservation

        const toKeep = userReservation.reservationsList
            .filter(res => {
                return res.id!==toAdd.id;
            });

        setUserReservation({reservationsList: [...toKeep, toAdd]});
        setOpen(false);

    };

    const displayResumeTable = (reservation, index)=>{

        return (
            <Paper className={classes.root} key={index}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} align="left">
                                <Typography variant="h6" color="textSecondary" component="p" >
                                    {reservation.Formation.name}
                                </Typography>
                            </TableCell>
                            <TableCell align="right" colSpan={2}>
                                <Fab  aria-label="add" variant="extended" className={classes.fab} onClick={()=>handleAddAssistante(reservation)}>
                                    <AddIcon />
                                   assistante

                                </Fab>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    {   //si on a des réservations annexes, les affiches, sinon, dis simplement qu'il n'y en a pas
                        reservation.Assistantes.length>0 && (
                            <>
                                <TableHead>
                                    <TableRow>

                                        <TableCell align="left">
                                            Prénom
                                        </TableCell>
                                        <TableCell align="left">
                                            Nom
                                        </TableCell>
                                        <TableCell align="left">
                                            Prix
                                        </TableCell>
                                        <TableCell align="left">
                                            Supprimer
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reservation.Assistantes.map(assistante => {
                                        return(
                                            <TableRow key={reservation.id}>
                                                <TableCell component="td" scope="row">
                                                    {assistante.firstName}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {assistante.lastName}
                                                </TableCell>
                                                <TableCell  component="td" align="left">
                                                    {displayPrice(reservation)}
                                                </TableCell>
                                                <TableCell component="td" align="left">
                                                    <Fab aria-label="delete" className={classes.fab} onClick={()=>handleDelete(reservation.id, assistante.id)}>
                                                        <DeleteIcon />
                                                    </Fab>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </>
                        )

                    }
                    {
                        reservation.Assistantes.length===0 &&
                        (
                            <TableCell colSpan={4} component="td" scope="row">
                                Aucune assistante
                            </TableCell>
                        )
                    }

                </Table>

            </Paper>
        )
    };



    return (
        <>
            {
                formatedReservations.map((res, index)=>{
                    return displayResumeTable(res, index);
                })
            }
            <NamePrompter open={open} onClose={handleClosePrompter} assistantesList={assistantesList}/>

        </>
    );
}
